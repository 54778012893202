import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../components/DataTable2';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import axiosPrivate from '../../../hooks/axiosPrivate';
import Loader from '../../../context/Loader/Loader';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { errorToast, successToast } from '../../../utils/Toaster';

const Users = (props) => {
	const [projects, setProjects] = useState([]);
	const [client, setClient] = useState([]);
	const [team, setTeam] = useState([]); 
	const {loading,setIsLoading} = useContext(LoaderContext);
	

	const headers = [
    { name: 'Client Name', field: 'client_user', sortable: true, classKey: 'w60' },
		
		{ name: 'Project Name', field: 'project_name', sortable: true, classKey: '' },

		{ name: 'Team Name', field: 'team_name', sortable: true, classKey: '' },

		{ name: 'Employee Names', field: 'employee_name', sortable: true, classKey: '' },
		
		{ name: 'Hours', field: 'estimated_hours', sortable: true, classKey: '' },
		
		{ name: 'Type', field: 'type', sortable: true, classKey: '' },
		
		{ name: 'Action', field: 'action', classKey: '' },
	];

	const searchItems = ['project_name'];
	




	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setIsLoading(true);
		axiosPrivate
		.get('all-projects-details', options)
		.then((projects) => {
			// setAlertMessage({ message: "Projects fetched successfully" });

			const projectData = projects?.data?.data?.map((value) => {
				let buttons = [];
				if (true)
					buttons.push(
						<Link
							key="editButton##1"
							type="button"
							to={`/admin/project-list/edit/${value.project_id}`}
							className="btn btn-icon btn-outline-info mr-1"
							title="Edit"
						>
							<i className="fa fa-edit"></i>
						</Link>
						
					);
				if (true)
					buttons.push(
						<button
							key="deleteButton##1"
							type="button"
							data-id={value.id}
							// onClick={() => projectDeleteHandler(value.id)}
							className="btn btn-icon js-sweetalert btn-outline-danger"
							title="Delete"
						>
							<i className="fa fa-trash-o text-danger"></i>
						</button>
					);
	
				value['action'] = buttons.length > 0 ? buttons : '-';
				
				const teamMembers = value?.teams?.[0]?.team_members;
				value["team_name"] = value?.teams?.[0]?.team_name;

				value["employee_name"] = teamMembers ? teamMembers.map((team_member) => team_member.employee_name) .join(', ') : '';
	
				return value;
			});
			setProjects(projectData);

			setIsLoading(false);
		})
		.catch((error) => {
			setIsLoading(false);
		});
		
	
				
	}, []); 

	const projectDeleteHandler = async (id) => {
		setIsLoading(true);
		axiosPrivate
			.delete(`projects/delete/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setProjects([]);
					successToast('User deleted successfully');
					await refreshTable();
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				errorToast(error?.response?.data?.message || "Something went wrong!");
				});
	};


	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			setIsLoading(true);
			axiosPrivate
				.get('all-projects-details', options)
				.then((projects) => {
					const projectData = projects.data.data.map((value, key) => {
						let buttons = [];
						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon btn-outline-info mr-1"	
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									// onClick={() => projectDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert btn-outline-danger"
									title="Delete"
								>
									<i className="fa fa-trash-o text-danger"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});
					setProjects(projectData);
					resolve(true);
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					reject(error);
					if (error.response) {
						errorToast(error.response.data.message);
					}
				});
		});
	};

	return (
		<>
			<div> { loading ? (<Loader />) : (
				<div>
					<div className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}>
						<div className="container-fluid">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<ul className="nav nav-tabs page-header-tab">
									<li className="nav-item">
										<a
											className="nav-link active"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-list"
										>
											All
										</a>
									</li>
									{/* <li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-view"
										>
											View
										</a>
									</li> */}
									<li className="nav-item">
										{/* <a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-Request"
										>
											Leave Request
										</a> */}
									</li>
								</ul>
								<div className="header-action">
								<Link to="add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
								</div>

							</div>
							
						</div>
					</div>
					<div className="section-body mt-3">

		
<div className="container-fluid">


	<div className="tab-content mt-3">

	
		<div
			className="tab-pane fade show active"
			id="user-list"
			role="tabpanel"
		>
			<div className="card">
				<div className="card-header">
					<h3 className="card-title">PROJECT LIST</h3>
				</div>
				<div className="card-body">
					<div className="table-responsive">
						{
							<DataTable2
								lists={projects}
								headers={headers}
								searchItems={searchItems}
								
							/>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
				</div> )}
			</div> 
			
		</>
	);
};

export default Users;
