import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './ExpensesStyle.module.css';

const validationRules = {
	// expense_type: Yup.string()
	// 	.max(120, 'Name must not exceed 120 characters')
	// 	.required('Expenses type is required'),
	expense_type_id: Yup.string().required('Role is required'),
};

const AddExpenses = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [tableData, setTableData] = useState([
		{
			expense_date: '',
			expense_amount: '',
			expense_description: '',
			// files: '',
		},
	]);

	const UserId = JSON.parse(localStorage.getItem('userData'));
	const Id = UserId.userId;

	useEffect(() => {
		axiosPrivate
			.get('expensetypes')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	const initialValues = [
		{
			employee_id: Id,
			expense_type_id: '',
			expense_date: '',
			expense_amount: '',
			expense_description: '',
			// files: '',
		},
	];

	console.log('initialValues', initialValues);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var values = {
				id: UserId.userId,
				expense_type_id: values.expense_type_id,
				expense: tableData,
			};
			axiosPrivate
				.post('expenses/create', values)
				// alert('check')

				.then((response) => {
					setAlertMessage({ message: 'New designation created successfully' });
					navigate('/admin/expenses');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	const addNewRow = (eve) => {
		eve.preventDefault();
		setTableData([
			...tableData,
			{
				expense_date: '',
				expense_amount: '',
				expense_description: '',
				// files: '',
			},
		]);
	};

	const removeExpensesTask = (index, eve) => {
		eve.preventDefault();
		if (tableData.length > 1) {
			setTableData(tableData.filter((_, i) => i !== index));
		}
		return true;
	};

	const handleChange = (evnt, index) => {
		const { name, value } = evnt.target;
		setTableData(
			tableData.map((row, i) => (i === index ? { ...row, [name]: value } : row))
		);
	};

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/expenses', add: '/admin/expenses/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Expenses</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="role"
														name="expense_type_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.expense_type_id}
													>
														<option>Select Expenses Type</option>
														{roles.map((role, key) => (
															<option value={role.id}>
																{role.expense_type}
															</option>
														))}
													</select>
													{formik.touched.expense_type_id &&
													formik.errors.expense_type_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.expense_type_id}
														</div>
													) : null}
												</div>
											</div>

											{tableData.map((val, index) => (
												<>
													<table className="table w-100">
														<thead key={index}>
															<tr>
																<th>
																	<div className="form-group">
																		<label>
																			<strong>Date</strong>{' '}
																		</label>
																		<input
																			className="form-control "
																			type="date"
																			onChange={(evnt) =>
																				handleChange(evnt, index)
																			}
																			value={val.expense_date}
																			name="expense_date"
																		/>
																	</div>
																</th>
																<th>
																	<div className="form-group">
																		<label>
																			<strong>Amount</strong>
																		</label>
																		<input
																			className="form-control "
																			type="number"
																			onChange={(evnt) =>
																				handleChange(evnt, index)
																			}
																			value={val.expense_amount}
																			name="expense_amount"
																		/>
																	</div>
																</th>
																<th>
																	<div className="form-group">
																		{' '}
																		<label>
																			<strong>Description</strong>
																		</label>
																		<input
																			className="form-control"
																			type="text"
																			onChange={(evnt) =>
																				handleChange(evnt, index)
																			}
																			value={val.expense_description}
																			name="expense_description"
																		/>
																	</div>
																</th>
																{/* <th>
                                                                    <div className="form-group">
                                                                        <label><strong>File</strong></label>
                                                                        <input
                                                                            className="form-control "
                                                                            type="file"
                                                                            onChange={(evnt) => handleChange(evnt, index)}
                                                                            value={val.files}
                                                                            name="files" />
                                                                    </div>
                                                                </th> */}
																<th>
																	<div className="form-group text-center">
																		<label>
																			<strong>Action</strong>
																		</label>
																		<br />
																		<div className="d-flex justify-content-around">
																			<div className="btn d-flex">
																				<div>
																					<button
																						className="border-0 bg-white "
																						onClick={(eve) => addNewRow(eve)}
																					>
																						<GrAddCircle size={20} />
																					</button>
																				</div>
																				<div>
																					<button
																						className="border-0 bg-white"
																						onClick={(eve) =>
																							removeExpensesTask(index, eve)
																						}
																					>
																						<GrSubtractCircle size={20} />
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</th>
															</tr>
														</thead>
													</table>
												</>
											))}

											<div className="col-12 mt-3">
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/expenses')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddExpenses;
