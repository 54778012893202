import React, { useContext, useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import AddTicketReply from "./AddTicketReply";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "./TicketProjectList.css";

const TicketList = (props) => {
  const { id } = useParams();
  const [ticketDetails, setTicketDetails] = useState([]);
  const [showReplyComponent, setShowReplyComponent] = useState(false);
  const navigate = useNavigate();
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [projectId, setProjectId] = useState("");

  let userId = "";
  if (localStorage.getItem("userDetails")) {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    userId = userData?.userId;
  }
  async function getData() {
    try {
      setIsLoading(true);
      const ticket = await axiosPrivate.post(`tickets-by-task/${id}`);
      const ticketData = await ticket.data.data[0];
      const thread_id = await ticketData.thread_id;
      const response = await axiosPrivate.post(`/tickets-by-thread`, {
        thread_id: thread_id,
      });
      setProjectId(ticketData?.project_id);
      setTicketDetails(response.data.data);
      setIsLoading(false);
      // setAlertMessage({ message: "tickets fetched successfully" });
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching tickets:", error);
    }
  }

  useEffect(() => {
    getData();
    window.scrollTo({ bottom: 0, behavior: "smooth" });
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const optionsDate = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", optionsTime).format(
      date
    );
    return `${formattedDate} | ${formattedTime}`;
  }

  const handleReplyButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowReplyComponent(true);
  };

  const handleCloseReplyComponent = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowReplyComponent(false);
  };

  const handleGoBack = () => {
    navigate(-1); // This will go back one step in the history
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="tab-content m-3">
          {showReplyComponent && (
            <AddTicketReply ticketDetails={ticketDetails} />
          )}
          <div
            className="tab-pane fade show active"
            id="user-list"
            role="tabpanel"
          >
            <div className="card">
              <div className="card-body">
                <div className="p-2 text-center">
                  {showReplyComponent && (
                    <button
                      type="button"
                      className="btn btn-secondary ml-2"
                      onClick={handleCloseReplyComponent}
                    >
                      Close
                    </button>
                  )}
                </div>
                <div className="header-wrapper">
                  <div className="msg-header">
                    <ol
                      className="headerroutes breadcrumb m-0 bg-none "
                      style={{ padding: "0rem" }}
                    >
                      <li className="item">
                        <span className="route-heading item mx-2">
                          {ticketDetails[0]?.title}
                        </span>
                      </li>

                      <li className="item color-gray">|</li>
                      <li className="item">
                        <span className="route-heading item mx-2">
                          {ticketDetails[0]?.subject}
                        </span>
                      </li>
                      <li className="item color-gray">|</li>
                      <li className="item">
                        <span className="route-heading item mx-2">
                          {ticketDetails[ticketDetails?.length - 1]?.status}
                        </span>
                      </li>

                      <li className="item color-gray">|</li>
                      <li className="item">
                        <span className="route-heading item mx-2">
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "4" && (
                            <button
                              className="btn"
                              style={{
                                color: "white",
                                background: " #ff5656",
                                minWidth: "50px",
                                height: "20px",
                                marginTop: "1px",
                                paddingTop: "0px",
                                transition: "background-color 0.3s ease",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor =
                                  "rgb(255, 89, 89)")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "#C70039")
                              }
                            >
                              Critical
                            </button>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "3" && (
                            <button
                              className="btn"
                              style={{
                                color: "white",
                                background: " #ff5656",
                                minWidth: "50px",
                                height: "20px",
                                marginTop: "1px",
                                paddingTop: "0px",
                                transition: "background-color 0.3s ease",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor =
                                  "rgb(255, 114, 114)")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "#FF6969")
                              }
                            >
                              High
                            </button>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "2" && (
                            <button
                              className="btn"
                              style={{
                                color: "white",
                                background: "#41db41",
                                minWidth: "50px",
                                height: "20px",
                                marginTop: "1px",
                                paddingTop: "0px",
                                transition: "background-color 0.3s ease",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = "green")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "#41db41")
                              }
                            >
                              Medium
                            </button>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "1" && (
                            <button
                              className="btn"
                              style={{
                                color: "white",
                                background: "#ffc458",
                                minWidth: "50px",
                                height: "20px",
                                marginTop: "1px",
                                paddingTop: "0px",
                                transition: "background-color 0.3s ease",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = "orange")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = "#ffc458")
                              }
                            >
                              Low
                            </button>
                          )}
                        </span>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="chat-page">
                  <div className="msg-inbox">
                    <div className="chats">
                      <div className="msg-page">
                        {ticketDetails.length > 0 &&
                          ticketDetails?.map((ticket) => (
                            <>
                              {ticket?.raised_by_user_id != userId ? (
                                <div className="received-chats ">
                                  <div className="d-flex align-items-baseline">
                                    <Avatar
                                      className="user-icon mr-2"
                                      label={ticket?.raised_by_user
                                        ?.charAt(0)
                                        .toUpperCase()}
                                      shape="circle"
                                      size="large"
                                    />
                                    <div className="activeP">
                                      <p>{ticket?.raised_by_user}</p>
                                    </div>
                                    <span className="time ml-2">
                                      {formatDate(ticket.created_at)}
                                    </span>
                                  </div>
                                  <div className="received-msg">
                                    <div className="received-msg-inbox">
                                      <div className="block">
                                        <p>{ticket?.description}</p>
                                        <p>
                                          <span>Assigned To: </span>
                                          {ticket?.assigned_users?.map(
                                            (user, index) => (
                                              <span key={index}>
                                                <Avatar
                                                  icon="pi pi-user"
                                                  shape="circle"
                                                  size="medium"
                                                />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {" "}
                                                  {user.name}{" "}
                                                </span>
                                              </span>
                                            )
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="outgoing-chats">
                                  <div className="outgoing-msg">
                                    <div className="outgoing-chats-msg">
                                      <div className="block">
                                        <p>{ticket?.description}</p>
                                        <p>
                                          <span>Assigned To: </span>
                                          {ticket?.assigned_users?.map(
                                            (user, index) => (
                                              <span key={index}>
                                                <Avatar
                                                  icon="pi pi-user"
                                                  shape="circle"
                                                  size="medium"
                                                />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {" "}
                                                  {user.name}{" "}
                                                </span>
                                              </span>
                                            )
                                          )}
                                        </p>
                                      </div>
                                      <span className="time">
                                        {formatDate(ticket.created_at)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {!showReplyComponent && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleReplyButtonClick}
                  >
                    Reply
                  </button>
                )}
                {!showReplyComponent && (
                  <button
                    type="button"
                    className="btn btn-secondary ml-2"
                    onClick={handleGoBack}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketList;
