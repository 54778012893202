import { useEffect } from 'react';
import {
	BrowserRouter,
	createBrowserRouter,
	Route,
	Router,
	RouterProvider,
	Routes,
} from 'react-router-dom';
import './App.css';
import Login from './components/Authentication/login';
import AuthMain from './components/Layouts/AuthMain';
import Main from './components/Layouts/Main';
import Clients from './components/Project/Clients/Clients';
import AlertState from './context/Alert/AlertState';
import AuthState from './context/Auth/AuthState';
import SignIn from './Pages/Auth/SignIn';
import SignUp from './Pages/Auth/SignUp';
import DashBoard from './Pages/DashBoard/DashBoard';
import Accounts from './Pages/hrms/Accounts/Accounts';
import Activities from './Pages/hrms/Activities/Activities';
import AddAssets from './Pages/hrms/Asssets/AddAssets';
import Assets from './Pages/hrms/Asssets/Assets';
import EditAssets from './Pages/hrms/Asssets/EditAssets';
import AddBranch from './Pages/hrms/Branch/AddBranch';
import Branch from './Pages/hrms/Branch/Branch';
import EditBranch from './Pages/hrms/Branch/EditBranch';
import AddClient from './Pages/hrms/Client/AddClient';
import Client from './Pages/hrms/Client/Client';
import EditClient from './Pages/hrms/Client/EditClient';
import AddDepartment from './Pages/hrms/Departments/AddDepartment';
import Departments from './Pages/hrms/Departments/Departments';
import EditDepartment from './Pages/hrms/Departments/EditDepartment';
import AddDesignation from './Pages/hrms/Designations/AddDesignations';
import Designation from './Pages/hrms/Designations/Designations';
import EditDesignation from './Pages/hrms/Designations/EditDesignation';
import UserRegistration from './Pages/hrms/UserRegistration/UserRegistration';
import AddUserRegistration from './Pages/hrms/UserRegistration/AddUserRegistration';
import EditUserRegistration from './Pages/hrms/UserRegistration/EditUserRegistration';
import Events from './Pages/hrms/Events/Events';
import AddExpenses from './Pages/hrms/Expenses/AddExpenses';
import EditExpenses from './Pages/hrms/Expenses/EditExpenses';
import Expenses from './Pages/hrms/Expenses/Expenses';
import AddExpensesType from './Pages/hrms/ExpensesType/AddExpensesType';
import EditExpensesType from './Pages/hrms/ExpensesType/EditExpensesType';
import ExpensesType from './Pages/hrms/ExpensesType/ExpensesType';
import Holidays from './Pages/hrms/Holidays/Holidays';
import Payroll from './Pages/hrms/Payroll/Payroll';
import AddPermission from './Pages/hrms/Permission/AddPermission';
import EditPermission from './Pages/hrms/Permission/EditPermission';
import Permission from './Pages/hrms/Permission/Permission';
import Report from './Pages/hrms/Report/Report';
import AddRolePermission from './Pages/hrms/RolePermission/AddRolepermission';
import EditRolePermission from './Pages/hrms/RolePermission/EditRolePermission';
import RolePermission from './Pages/hrms/RolePermission/RolePermission';
import AddRoles from './Pages/hrms/Roles/AddRoles';
import EditRoles from './Pages/hrms/Roles/EditRoles';
import Roles from './Pages/hrms/Roles/Roles';
import AddTeam from './Pages/hrms/Team/AddTeam';
import EditTeam from './Pages/hrms/Team/EditTeam';
import Team from './Pages/hrms/Team/Team';
import Edituserrole from './Pages/hrms/UserRole/Edituserrole';
import Userrole from './Pages/hrms/UserRole/Userrole';
import AddUser from './Pages/hrms/Users/AddUser';
import EditUser from './Pages/hrms/Users/EditUser';
import Users from './Pages/hrms/Users/Users';
import JobPortalApplicants from './Pages/jobportal/Applicants/Applicants';
import JobPortalDashboard from './Pages/jobportal/Dashboard/Dashboard';
import JobPortalPositions from './Pages/jobportal/Positions/Positions';
import JobPortalResumes from './Pages/jobportal/Resumes/Resumes';
import JobPortalSettings from './Pages/jobportal/Settings/Settings';

import ProjectClients from './Pages/project/Clients/Clients';

import ProjectDashboard from './Pages/project/Dashboard/Dashboard';
import AddPost from './Pages/project/Post/AddPost';
import EditPost from './Pages/project/Post/EditPost';
import Post from './Pages/project/Post/Post';
import AddTaskAssign from './Pages/project/TaskAssign/AddTaskAssign';
import EditTaskAssign from './Pages/project/TaskAssign/EditTaskAssign';
import TaskAssign from './Pages/project/TaskAssign/TaskAssign';
import AddProject from './Pages/project/ProjectList/AddProject';
import EditProject from './Pages/project/ProjectList/EditProject';
import ProjectList from './Pages/project/ProjectList/ProjectList';
import AddTask from './Pages/project/ProjectTaskList/AddTask';
import EditAddTask from './Pages/project/ProjectTaskList/EditAddTask';
import ProjectTaskList from './Pages/project/ProjectTaskList/ProjectTaskList';
import AddProjectTask from './Pages/project/ProjectTaskList/AddProjectTask';
import EditProjectTask from './Pages/project/ProjectTaskList/EditProjectTask';
import EditChildTask from './Pages/project/ProjectTaskList/EditChildTask';
import ProjectTicketDetails from './Pages/project/TicketDetails/TicketDetails';
import TicketProjectList from './Pages/project/TicketDetails/TicketProjectList';
import AddProjectTicketDetails from './Pages/project/TicketDetails/AddProjectTicketDetails';
import ProjectTicketList from './Pages/project/TicketList/TicketList';
import AddProjectTicket  from './Pages/project/TicketList/AddTicketList';
import EditProjectTicket  from './Pages/project/TicketList/EditTicketList';
import ProjectTaskboard from './Pages/project/TodoList/Taskboard';
import ProjectTodoList from './Pages/project/TodoList/TodoList';
import CustomDataTable from './Pages/project/TodoList/CustomDataTable';

import UserPermission from './Pages/hrms/UserPermission/UserPermission';
import TicketList from './components/Project/TicketList/TicketList';
import TicketDetails from './Pages/project/TicketDetails/TicketDetails';
import AddTicketReply from './Pages/project/TicketDetails/AddTicketReply';
import AddNewProjectTask from './Pages/project/ProjectTaskList/AddNewProjectTask';
import LoaderState from './context/Loader/LoaderState';
import { ToastContainer } from 'react-toastify';
function App() {
	return (
		<BrowserRouter>
				<LoaderState>
			<AuthState>
				<AlertState>
					<Routes>
						<Route path="/" element={<AuthMain />}>
							<Route path="signin" element={<SignIn />} />
							{/* <Route path="signup" element={<SignUp />} /> */}
						</Route>

						<Route path="/admin" element={<Main />}>
							<Route path="" element={<DashBoard />} />

							<Route path="users">
								<Route path="" element={<Users />} />
								<Route path="add" element={<AddUser />} />
								<Route path="edit/:id" element={<EditUser />} />
								<Route
									path="permissions/:userId/:roleId"
									element={<UserPermission />}
								/>
							</Route>

							<Route path="ticket-list" element={<TicketList/>} />

							<Route path="departments" element={<Departments />} />
							<Route path="departments/add" element={<AddDepartment />} />
							<Route path="departments/edit/:id" element={<EditDepartment />} />

							{/* <Route path="designations" element={<Designation />} />
							<Route path="designations/add" element={<AddDesignation />} />
							<Route
								path="designations/edit/:id"
								element={<EditDesignation />}t
							/> */}

							<Route path="assets" element={<Assets />} />
							<Route path="assets/add" element={<AddAssets />} />
							<Route path="assets/edit/:id" element={<EditAssets />} />

							<Route path="expensetypes" element={<ExpensesType />} />
							<Route path="expensetypes/add" element={<AddExpensesType />} />
							<Route
								path="expensetypes/edit/:id"
								element={<EditExpensesType />}
							/>

							{/* <Route path="roles" element={<Roles />} />
							<Route path="roles/add" element={<AddRoles />} />
							<Route path="roles/edit/:id" element={<EditRoles />} /> */}

							<Route path="permission" element={<Permission />} />
							<Route path="permission/add" element={<AddPermission />} />
							<Route path="permission/edit/:id" element={<EditPermission />} />

							<Route path="userrole" element={<Userrole />} />
							<Route path="userrole/edit/:id" element={<Edituserrole />} />

							<Route path="rolepermission" element={<RolePermission />} />
							<Route
								path="rolepermission/add"
								element={<AddRolePermission />}
							/>
							<Route
								path="rolepermission/edit/:id"
								element={<EditRolePermission />}
							/>
                        <Route path="roles/edit/:id" element={<EditRoles />} />
                           

							<Route path="team" element={<Team />} />
							<Route path="team/add" element={<AddTeam />} />
							<Route path="team/edit/:id" element={<EditTeam />} />

							<Route path="client" element={<Client />} />
							<Route path="client/add" element={<AddClient />} />
							<Route path="client/edit/:id" element={<EditClient />} />

							<Route path="branch" element={<Branch />} />
							<Route path="branch/add" element={<AddBranch />} />
							<Route path="branch/edit/:id" element={<EditBranch />} />


							<Route path="expenses" element={<Expenses />} />
							<Route path="expenses/add" element={<AddExpenses />} />
							<Route path="expenses/edit/:id" element={<EditExpenses />} />

							<Route path="project-task-list" element={<ProjectTaskList />} />
							<Route path="project-task-list/add" element={<AddProjectTask />} />
							<Route
								path="project-task-list/edit/:id"
								element={<EditProjectTask />}


							/>
							<Route
								path="project-child-task-list/edit/:id"
								element={<EditChildTask />}


							/>
							

							<Route path="usersregistration" element={<UserRegistration />} />
							<Route path="usersregistration/add" element={<AddUserRegistration />} />
							<Route path="usersregistration/edit/:id" element={<EditUserRegistration />} />
							<Route path="activities" element={<Activities />} />
							<Route path="holidays" element={<Holidays />} />
							<Route path="events" element={<Events />} />
							<Route path="payroll" element={<Payroll />} />
							<Route path="accounts" element={<Accounts />} />
							<Route path="report" element={<Report />} />
							<Route path="project-dashboard" element={<ProjectDashboard />} />
							<Route
								path="project-list"
								element={<ProjectList boxOpen="true" />}
							/>
							<Route
								path="project-list/add"
								element={<AddProject boxOpen="true" />}
							/>
							<Route path="project-list/edit/:id" element={<EditProject />} />
							<Route
								path="project-taskboard"
								element={<ProjectTaskboard boxOpen="true" />}
							/>
							<Route
								path="project-taskboard/add"
								element={<AddTask boxOpen="true" />}
							/>
							<Route path="project-taskboard/edit/:id" element={<EditAddTask />} />
							<Route path="project-ticket" element={<ProjectTicketList />} />
							<Route
								path="project-ticket/add"
								element={<AddProjectTicket boxOpen="true" />}
							/>
							<Route path="project-ticket/edit/:id" element={<EditProjectTicket />} />
							<Route
								path="project-ticket-details"
								element={<ProjectTicketDetails />}
								
							/>
							<Route path="project-ticket-details/add" element={<AddProjectTicketDetails />} />
							<Route path="project-task/add/:id" element={<AddNewProjectTask />} />
							<Route path="project-ticket-list/view/:id" element={<TicketProjectList />} />
							<Route path="project-ticket/add-reply/:id" element={<AddTicketReply/>} />
							<Route path="project-clients" element={<ProjectClients />} />
							<Route path="project-clients/add" element={<AddClient />} />
							<Route path="project-clients/edit/:id" element={<EditClient />} />

							<Route path="project-todo" element={<ProjectTodoList />} />
							<Route path="project-todo-data" element={<CustomDataTable />} />

							<Route
								path="jobportal-dashboard"
								element={<JobPortalDashboard boxClose="true" />}
							/>
							<Route
								path="jobportal-positions"
								element={<JobPortalPositions />}
							/>
							<Route
								path="jobportal-applicants"
								element={<JobPortalApplicants />}
							/>
							<Route path="jobportal-resumes" element={<JobPortalResumes />} />
							<Route
								path="jobportal-settings"
								element={
									<JobPortalSettings
										statisticsClose="true"
										friendListClose="true"
									/>
								}
							/>


							<Route path="post" element={<Post />} />
							<Route path="post/add" element={<AddPost />} />
							<Route path="post/edit/:id" element={<EditPost />} />

							<Route path="taskassign" element={<TaskAssign />} />
							<Route path="taskassign/add" element={<AddTaskAssign />} />
							<Route path="taskassign/edit/:id" element={<EditTaskAssign />} />

							{/* <Route path="clients" element={<Clients />} /> */}
						</Route>
					</Routes>
				</AlertState>
			</AuthState>
					</LoaderState>
					<ToastContainer/>
		</BrowserRouter>
	);
}

export default App;
