import { useRef } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useContext, useEffect, useState } from "react";
import Loader from "../../../context/Loader/Loader";
import LoaderContext from "../../../context/Loader/LoaderContext";

const Employee = (props) => {
  const sparkline2 = useRef(null);
  const sparkline3 = useRef(null);
  const sparkline4 = useRef(null);
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [employeeState, setEmployeeState] = useState([]);

  const headers = [
    { name: "ROLE NAME", field: "role_name", sortable: true, classKey: "w60" },
    { name: "NAME", field: "name", sortable: true, classKey: "" },
    // { name: 'ROLE', field: 'role', sortable: true, classKey: '' },
    { name: "DESIGNATION", field: "designation", sortable: true, classKey: "" },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    // { name: 'MOBILE', field: 'mobile', sortable: true, classKey: '' },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };

    setIsLoading(true);

    axiosPrivate
      .get("users", options)
      .then((response) => {
        // setAlertMessage({ message: "Users fetched successfully" });
        const usersData = response.data.data.map((user) => {
          let buttons = [];

          if (true) {
            buttons.push(
              <Link
                key="editButton"
                to={`/admin/usersregistration/edit/${user.id}`}
                className="btn btn-icon btn-outline-info mr-1"
                title="Edit"
              >
                <i className="fa fa-edit" />
              </Link>
            );
          }

          if (true) {
            buttons.push(
              <button
                key="deleteButton"
                data-id={user.id}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger" />
              </button>
            );
          }

          user.action = buttons.length > 0 ? buttons : "-";

          return user;
        });

        setEmployeeState(usersData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  // const employeeDeleteHandler = async (id) => {
  // 	axiosPrivate
  // 		.delete(`companies/${id}`)
  // 		.then(async (response) => {
  // 			if (response.data.code == 200) {
  // 				setEmployeeState([]);
  // 				setAlertMessage({ message: 'company deleted successfully' });
  // 				await refreshTable();
  // 			}
  // 		})
  // 		.catch((error) => {
  // 			if (error.response) {
  // 				//response status is an error code
  // 				console.log(error.response.status);
  // 			} else if (error.request) {
  // 				//response not received though the request was sent
  // 				console.log(error.request);
  // 			} else {
  // 				//an error occurred when setting up the request
  // 				console.log(error.message);
  // 			}
  // 		});
  // };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("companies", options)
        .then((companies) => {
          const companiesData = companies.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  // onClick={() => employeeDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setEmployeeState(companiesData);
          resolve(true);
        })
        .catch((error) => {
        });
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div
              className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
            >
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="Employee-tab"
                        data-toggle="tab"
                        href="#Employee-list"
                      >
                        All
                      </a>
                    </li>
                    {/* <li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-view"
										>
											View
										</a>
									</li> */}
                    <li className="nav-item">
                      {/* <a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-Request"
										>
											Leave Request
										</a> */}
                    </li>
                  </ul>
                  <div className="header-action">
                    <Link to="add" className="text-decoration-none">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <i className="fe fe-plus mr-2" />
                        Add
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-body mt-3">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="user-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">USERS LIST</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          {
                            <DataTable2
                              lists={employeeState}
                              headers={headers}
                              searchItems={searchItems}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Employee;
