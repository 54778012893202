import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import LoaderContext from "../../context/Loader/LoaderContext";
import Todolist from "../project/TodoList/TodoList";
import Loader from "../../context/Loader/Loader";
import TicketList from "../project/TicketDetails/TicketProjectList";

const DashBoard = () => {
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [employee, setEmployee] = useState([]);
  const [client, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    try {
      axiosPrivate.get("users").then((user) => {
        const employee = user?.data?.data.filter((user) => user?.role_id == 2);
        const client = user?.data?.data.filter((user) => user?.role_id == 3);
        setEmployee(employee);
        setClients(client);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    try {
      axiosPrivate.get("projects").then((project) => {
        setProjects(project?.data?.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }

    //get tickets
    try {
      axiosPrivate.get("tickets").then((ticket) => {
        setTickets(ticket?.data?.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className={`section-body marginTop} mt-3`}>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className={`section-body mb-4 mt-3}`}>
                    <h4>Welcome {userData.userName}!</h4>
                  </div>
                </div>
              </div>
              {userData?.role == 1 && (
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body ribbon">
                        <div className="ribbon-box  orange">
                          {employee?.length}
                        </div>
                        <Link
                          to="/admin/usersregistration"
                          className="my_sort_cut text-muted"
                        >
                          <i className="icon-users" />
                          <span>Employee</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body ribbon">
                        <div className="ribbon-box  orange">
                          {client?.length}
                        </div>
                        <Link
                          to="/admin/client"
                          className="my_sort_cut text-muted"
                        >
                          <i className="icon-like" />
                          <span>Clients</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body ribbon">
                        <div className="ribbon-box orange">
                          {projects?.length}
                        </div>
                        <Link
                          to="/admin/project-list"
                          className="my_sort_cut text-muted"
                        >
                          <i className="icon-calendar" />
                          <span>Projects</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div
                  className="tab-pane fade show active"
                  id="user-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Todolist userName={userData?.userName} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DashBoard;
