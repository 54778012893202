import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import DataTable2 from '../../../components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { errorToast, successToast } from '../../../components/common/Toaster';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AuthContext from '../../../context/Auth/AuthContext';

const Users = (props) => {
	const [userData, setUserData] = useState([]);
	const [refreshState, setRefreshState] = useState(false);
	const { checkPermissionExists } = useContext(AuthContext);

	const searchItems = ['name', 'email', 'role'];
	const headers = [
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'CONTACT NUMBER', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'ROLE', field: 'role', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	useEffect(() => {
		if (checkPermissionExists('Read-User')) {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('users', options)
				.then((users) => {
					const usersData = users?.data?.data?.map((value, key) => {
						let buttons = [];
						if (checkPermissionExists('Update-User'))
							buttons.push(
								<Link
									key={`editButton##${key}`}
									type="button"
									to={`/admin/users/edit/${value.id}`}
									className="btn btn-icon btn-outline-info mr-1"
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</Link>
							);
						if (true)
							buttons.push(
								<Link
									key={`userPermissionButton##${key}`}
									type="button"
									to={`/admin/users/permissions/${value.id}/${value.role_id}`}
									className="btn btn-icon btn-outline-primary mr-1"
									title="Permission"
								>
									<i className="fa fa-user"></i>
								</Link>
							);
						if (checkPermissionExists('Delete-User'))
							buttons.push(
								<button
									key={`deleteButton##${key}`}
									type="button"
									data-id={value.id}
									onClick={() => userDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert btn-outline-danger"
									title="Delete"
								>
									<i className="fa fa-trash-o"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						value['mobile'] = String(value.mobile);
						return value;
					});
					setUserData(usersData);
					setRefreshState(false);
				})
				.catch((error) => {
					if (error.response) {
						console.log(error.response.status);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log(error.message);
					}
					errorToast(error.message);
				});
		}
	}, [refreshState]);

	const userDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`users/${id}`)
			.then(async (response) => {
				if (response.data.code === 200) {
					setRefreshState(true);
					successToast(response.data.message);
				}
			})
			.catch((error) => {
				errorToast(error.message);
			});
	};

	return (
		<>
			<TopNavBar links={{ list: '/admin/users', add: checkPermissionExists('Create-User') ? '/admin/users/add' : '' }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={userData}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Users;
