import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './addAssetsStyle.module.css';

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
};

const EditAsset = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	//   const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});
	const [initialValues, setInitialValues] = useState({
		name: '',
	});

	// const [inputList, setInputList] = useState([{ name: '' }]);

	// const addMoreRow = () => {
	// 	setInputList([...inputList, { name: '' }]);
	// };

	// const removeRow = (index) => {
	// 	setInputList(inputList.filter((_, i) => i !== index));
	// };

	// const handleInputChange = (event, index) => {
	// 	const { name, value } = event.target;
	// 	setInputList(
	// 		inputList.map((Childrow, i) =>
	// 			i === index ? { ...Childrow, [name]: value } : Childrow
	// 		)
	// 	);
	// };

 
	useEffect(() => {
		axiosPrivate
			.get(`assets/${id}`)
			.then((response) => {
				if (response.data.code == 200) {
					setGetData({ ...response.data.data, password: '' });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
		// console.log("getData",getData)
	}, [getData]);
	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.put(`assets/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'assets updated successfully' });
					navigate('/admin/assets');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Assets</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Assets *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>

										{/* {inputList.map((row, index) => {
											return (
												<div className="table-responsive" key={index}>
													<table className="table table-striped">
														<tbody>
															<tr key={index}>
																<td className="50">
																	<input
																		className="w-100 form-control"
																		type="text"
																		name="name"
																		placeholder="✍️ code"
																		value={formik.values.name}
																		onChange={(event) =>
																			handleInputChange(event, index)
																		}
																	/>
																</td>
																<td className="50">
																	{inputList.length - 1 === index && (
																		<button
																			className="btn btn-secondary w-20 mx-3"
																			onClick={addMoreRow}
																		>
																			Add Row
																		</button>
																	)}
																	{inputList.length !== 1 && (
																		<svg
																			onClick={() => removeRow(index)}
																			xmlns="http://www.w3.org/2000/svg"
																			width="16"
																			height="16"
																			fill="currentColor"
																			style={{ color: 'red' }}
																			class="bi bi-trash"
																			viewBox="0 0 16 16"
																		>
																			<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
																			<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
																		</svg>
																	)}
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											);
										})} */}

										<div className="col-12">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>

											<button type="submit" className="btn btn-primary ml-2">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/assets')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditAsset;
