import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Client.module.css';

const phoneRegExp =
/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	
	name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
	
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
	mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
	password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must not exceed 25 characters")
    .required("Password is required"),
};

const AddCompany = () => {
	
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

 	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile: "",
			password: "",
			role_id: ""

		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			let formData = new FormData();
		
			formData.append("name", values.name);
			
			formData.append("email", values.email);
			formData.append("mobile", values.mobile);
			formData.append("password", values.password);
			formData.append("role_id", values.role_id);
			axiosPrivate
				.post('register', values)
				.then((response) => {
                    // console.log("response company",response)
 					setAlertMessage({ message: 'New client created successfully' });
					navigate('/admin/client');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	useEffect(() => {
		axiosPrivate.get('roles').then((user_roles) => {
			setRoles(user_roles?.data?.data);
		});
	}, []);

	return (
		<div>
 			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Client</strong>
									</div>

									<div className="card-body">
										<div className="row clearfix">
										<div className="col-lg-6 col-md-12 col-sm-12">
										<label>
                         Client Name:<span className="required">*</span>
                        </label>
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder=" Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name&& formik.errors.name? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                          Role:<span className="required">*</span>
                        </label>
                      <div className="form-group">
                        <select
                          className="form-control show-tick"
                          id="role_id"
                          name="role_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.role_id}
                        >
                          <option>Select Role</option>
                          {roles.map((role, key) => (
                            <option key={key + role.name} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.role_id && formik.errors.role_id ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.role_id}
                          </div>
                        ) : null}
                      </div>
                    </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Email:<span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email ID *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
						   {formik.touched.email &&
													formik.errors.email ? (
														<div className={classes.invalidDataError}>
															{formik.errors.email}
														</div>
													) : null}
                        </div>
                      </div>
					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Mobile Number:
                          <span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="number"
                            className="form-control"
                            placeholder=" Mobile Number "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                          />
                          {formik.touched.mobile &&
													formik.errors.mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.mobile}
														</div>
													) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Password:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>

											<div className="col-12">
												<hr className="mt-4" />
												<div className="table-responsive">
													<table className="table table-striped">
														<tbody></tbody>
													</table>
												</div>
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/client')}
												>
													Close
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCompany;
