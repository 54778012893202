import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { errorToast, successToast } from "../../../components/common/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";


const Permission = (props) => {

  const { loading, setIsLoading } = useContext(LoaderContext);
  const [permissions, setPermissions] = useState([]);
  const [refreshState, setRefreshState] = useState(false);

  const headers = [
    { name: "PERMISSION NAME", field: "name", sortable: true, classKey: "" },
    { name: "PERMISSion Type", field: "action", sortable: true, classKey: "" },
    { name: " ACTION", field: "action1", sortable: true, classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      .get("permissions", options)
      .then((permissions) => {

        const permissionsData = permissions.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key={`editButton##${key}`}
                type="button"
                to={`/admin/permission/edit/${value.id}`}
                className="btn btn-icon btn-outline-info mr-1"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (true)
            buttons.push(
              <button
                key={`deleteButton##${key}`}
                type="button"
                data-id={value.id}
                onClick={() => PermissionDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o"></i>
              </button>
            );
          value["action1"] = buttons.length > 0 ? buttons : "-";
          return value;
        });
        setPermissions(permissionsData);
        setRefreshState(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [refreshState]);

  const PermissionDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`permissions/destroy/${id}`)
      .then(async (response) => {
        if (response.data.code === 200) {
          setRefreshState(true);
          successToast(response.data.message);
        }
      })
      .catch((error) => {
        errorToast("Something went wrong!");
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div
              className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
            >
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="Employee-tab"
                        data-toggle="tab"
                        href="#Employee-list"
                      >
                        All
                      </a>
                    </li>
                    {/* <li className="nav-item">
									<a
										className="nav-link"
										id="Employee-tab"
										data-toggle="tab"
										href="#Employee-view"
									>
										View
									</a>
								</li> */}
                    <li className="nav-item">
                      {/* <a
										className="nav-link"
										id="Employee-tab"
										data-toggle="tab"
										href="#Employee-Request"
									>
										Leave Request
									</a> */}
                    </li>
                  </ul>
                  <div className="header-action">
                    <Link to="add" className="text-decoration-none">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <i className="fe fe-plus mr-2" />
                        Add
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-body mt-3">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="user-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">PERMISSION LIST</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          {
                            <DataTable2
                              lists={permissions}
                              headers={headers}
                              searchItems={searchItems}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Permission;
