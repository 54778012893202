import { Formik, useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Userrole.module.css';

 
const validationRules = {
	// designation_name: Yup.string()
	// 	.max(120, 'Name must not exceed 120 characters')
	// 	.required('Name is required'),
	// department_id: Yup.string().required('Role is required'),
};

const Edituserrole = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});
    const [multiSelect,setmultiSelect]=useState([])
 
	const [arrayData, setArrayData] = useState([]);
	const [roleData, setRoleData] = useState([]);

	const data = async () => {
		const empty = [];
		for (let i = 0; i < roles.length; i++) {
			empty.push(roles[i].name);
		}
		// {roles.length >0 && roles.map((val) => {
		setArrayData(empty);
		// })}
	};
	useEffect(() => {
		data();
	}, [roles]);

	useEffect(() => {
		axiosPrivate
			.get('roles')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get(`user-roles/${id}`)
			.then((response) => {
				console.log('reponse check', response.data.data);
				if (response.data.code === 200) {
					setGetData(...response.data.data);
                    // response.data.data.role_name.split(",")
                    console.log("checkdddddd",response.data.data[0].role_name)
                    setmultiSelect(response.data.data[0].role_name.split(","))
                    
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [roles]);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	const formik = useFormik({
		initialValues: {
			roles: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var data = [];
			roles.map((role) => {
				if (roleData.includes(role.name)) {
					data.push(role.id);
				}
			});
			var values = {
				roles: data,
			};

			console.log('values',values);
			axiosPrivate
				.put(`user-roles/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'user role updated successfully' });
					navigate('/admin/userrole');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							console.log('edit user role error');

							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>EDIT USER ROLE</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="user_name"
													name="user_name"
													type="text"
													className="form-control"
													placeholder="Name *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.user_name}
                                                    readOnly
												/>
												{formik.touched.user_name && formik.errors.user_name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.user_name}
													</div>
												) : null}
											</div>
										</div>

										{/* <div className="col-md-4 col-sm-12">
											<div className="form-group">
												<select
													className="form-control show-tick"
													id="role"
													name="department_id"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.department_id}
												>
													<option>Select Role</option>
													{roles.map((role, key) => (
														<option key={key + role.name} value={role.id}>
															{role.name}
														</option>
													))}
												</select>
												{formik.touched.department_id &&
												formik.errors.department_id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.department_id}
													</div>
												) : null}
											</div>
										</div> */}

										{/* multi select */}

										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<Multiselect
													isObject={false}
													options={arrayData}
													displayValue="name"
													showCheckbox
													onRemove={(eve) => {
														console.log(eve);
													}}
													name="roles"
													value={roles}
													onSelect={(eve) => setRoleData(eve)}
                                                    selectedValues={multiSelect}
												/>

												{formik.touched.role_name && formik.errors.role_name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.role_name}
													</div>
												) : null}
											</div>
										</div>

										{/* <div>
											<Select options={arrayData}></Select>
										</div> */}

										<div className="col-12">
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/userrole')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Edituserrole;
