import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Branch.module.css';

const validationRules = {
    company_id: Yup.string().required('Company name is required'),

	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Branch name is required'),
};

const AddDesignation = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		axiosPrivate
			.get('companies')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	const formik = useFormik({
		initialValues: {
            company_id:'',
			name: '',
 		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.post('branches/create', values)
				.then((response) => {
					setAlertMessage({ message: 'New branch created successfully' });
					navigate('/admin/branch');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
 			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Branch</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">

											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="role"
														name="company_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.company_id}
													>
														<option>Select Company Type</option>
														{roles.map((role, key) => (
															<option key={key + role.name} value={role.id}>
																{role.name}
															</option>
														))}
													</select>
													{formik.touched.company_id &&
													formik.errors.company_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.company_id}
														</div>
													) : null}
												</div>
											</div>

                                            <div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder=" Branch Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name &&
													formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>


											<div className="col-12">
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/branch')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddDesignation;
