import React, { useContext, useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './addProjectStyle.module.css';
import { FaCheck, FaTimes } from 'react-icons/fa';
import AddTeam from '../../hrms/Team/AddTeam';
import { errorToast, successToast} from '../../../utils/Toaster';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  title: Yup.string()
    .max(120, 'Project Name must not exceed 120 characters')
    .required('Project Name is required'),
  estimated_hours: Yup.string()
    .max(120, 'Time must not exceed 30 characters')
    .required('Time is required'),
  type: Yup.string()
    .max(120, 'Type must not exceed 30 characters')
    .required('Type is required'),
  client_user_id: Yup.string().required('Client Name is required'),
};

const AddProject = (props) => {
  let { id } = useParams();

  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [client, setClient] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [projectId, setProjectId] = useState(null);

  useEffect(() => {
    axiosPrivate
      .get('clients')
      .then((user_roles) => {
        setClients(user_roles?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      type: '',
      estimated_hours: '',
      client_user_id: '',
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {

      const existingProject = clients.find((client) => client.name === values.title);
      if (existingProject) {
        setFieldError('title', 'Project with this name already exists');
        return;
      }

      const requestData = {
        title: values.title,
        type: values.type,
        estimated_hours: values.estimated_hours,
        client_user_id: values.client_user_id,
      };

      try {
        const response = await axiosPrivate.post(`projects/add`, requestData);
        setFormSubmitted(true);
        successToast('Project added successfully');
        setProjectId(response?.data?.data?.insertId);
        formik.setFieldValue('project_id', response.data.data.insertId);
        console.log('API response:', response.data.data.insertId);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            errorToast(error?.response?.data?.message);
            for (let i = 0; i < error.response.data.errors.length; i++) {
              setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="tab-pane fade show active" id="user-add" role="tabpanel">
                <div className="card">
                  <div className="card-header">
                    <strong>Add Project</strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            placeholder="Client Name*"
                            id="client_user_id"
                            name="client_user_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_user_id}
                            required
                            disabled={formSubmitted}
                          >
                            <option>Select Client</option>
                            {clients.map((client, key) => (
                              <option key={key + client.name} value={client.id}>
                                {client.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.client_user_id && formik.errors.client_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.client_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Project Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            required
                            disabled={formSubmitted}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>{formik.errors.title}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="estimated_hours"
                            name="estimated_hours"
                            type="number"
                            className="form-control"
                            placeholder="Time(hours) *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.estimated_hours}
                            required
                            disabled={formSubmitted}
                          />
                          {formik.touched.estimated_hours && formik.errors.estimated_hours ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.estimated_hours}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            id="type"
                            name="type"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                            required
                            disabled={formSubmitted}
                          >
                            <option value="" disabled>
                              Select Project Type *
                            </option>
                            <option value="single">Single</option>
                            <option value="recurring">Recurring</option>
                          </select>
                          {formik.touched.type && formik.errors.type ? (
                            <div className={classes.invalidDataError}>{formik.errors.type}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody></tbody>
                        </table>
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary mr-1 ml-1 "
                         disabled={formSubmitted}>

                          <FaCheck />
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => navigate('/admin/project-list')}
                          disabled={formSubmitted}
                        >
                          <FaTimes />
                        </button>
                      </div>
                     
                    </div>
                  </div>
                
                 
                
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {formSubmitted && <AddTeam project_id={projectId} />} 
    </div>
    
  );
};

export default AddProject;

