import Fullcalender from '../../../components/common/fullcalender';

const Events = (props) => {
    return (
        <div>
            <div className={`section-body ${props.fixNavbar ? "marginTop" : ""} mt-3`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bline">
                                    <h3 className="card-title">Sara Hopkins</h3>
                                    <div className="card-options">
                                        <a href="/#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a>
                                        <a href="/#" className="card-options-fullscreen" data-toggle="card-fullscreen"><i className="fe fe-maximize" /></a>
                                        <a href="/#" className="card-options-remove" data-toggle="card-remove"><i className="fe fe-x" /></a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {/* <div id="calendar" /> */}
                                    <Fullcalender></Fullcalender>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Events