import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable2 from '../../../components/DataTable2';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { errorToast, successToast } from '../../../utils/Toaster';

const Clients = (props) => {
	const [clients, setClients] = useState([]);

	const headers = [
		{ name: '#', field: 'id', sortable: true, classKey: 'w60' },
		{ name: 'CLIENT NAME', field: 'client_name', sortable: true, classKey: '' },
    { name: 'NUMBER', field: 'client_number', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['client_name'];

	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('clients', options)
			.then((clients) => {
				console.log('clients', clients);

				const clientsData = clients.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/project-clients/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<i className="fa fa-edit"></i>
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => clientsDataDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<i className="fa fa-trash-o text-danger"></i>
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value; // console.log("uservalue",userData)
				});

				setClients(clientsData);
			})
			.catch((error) => {
			});
	}, []);


	const clientsDataDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`clients/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setClients([]);
					successToast('Client deleted successfully');
					await refreshTable();
				}
			})
			.catch((error) => {
				errorToast( error?.response?.data?.message ||"Something went wrong!");
			});
	};

	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('clients', options)
				.then((clients) => {
					const clientsData = clients.data.data.map((value, key) => {
						let buttons = [];
						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => clientsDataDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<i className="fa fa-trash-o text-danger"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});
					setClients(clientsData);
					resolve(true);
				})
				.catch((error) => {
				});
		});
	};

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/project-clients', add: '/admin/project-clients/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">CLIENT LIST</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={clients}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Clients;

