import React, { useContext } from "react";
import LoaderContext from "./LoaderContext";
import "./Loader.css";
const Loader = () => {
  const { loading } = useContext(LoaderContext);

  return (
    <>
      {loading ? (
        <div className="loaderWrapper">
          {" "}
          <div   className="loader">
            <div className="cell d-0"></div>
            <div className="cell d-1"></div>
            <div className="cell d-2"></div>

            <div className="cell d-1"></div>
            <div className="cell d-2"></div>

            <div className="cell d-2"></div>
            <div className="cell d-3"></div>

            <div className="cell d-3"></div>
            <div className="cell d-4"></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
