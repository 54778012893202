import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './TaskAssign.module.css';
const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	post_name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	parent_id: Yup.string().required('Role is required'),
};

const EditTaskAssign = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});
	const [initialValues, setInitialValues] = useState({
		post_name: '',
		parent_id: '',
	});

	useEffect(() => {
		axiosPrivate
			.get('task-type/all-task')
			.then((taskSub) => {
				// console.log("task-type/sub-task",taskSub)
				setRoles(taskSub?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get(`post-type/get-post-type/${id}`)
			.then((response) => {
				console.log('check default', response.data.data);

				if (response.data.code == 200) {
					setGetData({ ...response.data.data });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [roles]);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

console.log('getData',getData)

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.put(`post-type/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'post type updated successfully' });
					navigate('/admin/posttype');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							console.log('edit podt type error');

							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Post Type</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="form-group">
												<input
													id="post_name"
													name="post_name"
													type="text"
													className="form-control"
													placeholder="Post Type *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.post_name}
												/>
												{formik.touched.post_name && formik.errors.post_name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.post_name}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<select
													className="form-control show-tick"
													id="role"
													name="parent_id"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.parent_id}
												>
													<option>Select Task</option>
													{roles?.map((role, key) => (
														<option key={key + role.name} value={role.id}>
															{role.sub_task_name}
														</option>
													))}
												</select>
												{formik.touched.parent_id && formik.errors.parent_id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.parent_id}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12">
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/posttype')}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditTaskAssign;
