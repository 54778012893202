import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './addProjectTaskList.module.css';

const validationRules = {
  name: Yup.string().max(120, 'Task Name must not exceed 120 characters').required('Task Name is required'),
};

const AddProjectTask = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const requestData = {
        name: values.name,
      };

      try {
        const response = await axiosPrivate.post(`task-categories`, requestData);
        setAlertMessage({ message: 'Task added successfully' });
        navigate('/admin/project-task-list');
      } catch (error) {
        if (error.response && error.response.status === 422) {
          error.response.data.errors.forEach((err) => {
            setFieldError(err.param, err.msg);
          });
        }
      }
    },
  });

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="tab-pane fade show active" id="user-add" role="tabpanel">
              <div className="card">
                <div className="card-header">
                  <strong>Add Task</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Task Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>{formik.errors.name}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody></tbody>
                        </table>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate('/admin/project-Task-list')}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProjectTask;
