const Footer = () => {
    return (
        <>
            <div>
                <div className="section-body">
                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    Copyright © 2024{' '}
                                    <a href="https://webanixsolutions.com/">WEBaniX</a>
                                        .
                                    </div>
                                <div className="col-md-6 col-sm-12 text-md-right">
                                  
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default Footer
