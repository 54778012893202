import { Fragment, useContext} from "react";
import userPng from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";

const SideBar = () => {
  const navigate = useNavigate();
  const {authPermissions}=useContext(AuthContext);

  const logoutHandler = (e) => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userRefreshToken");
    localStorage.removeItem("userDetails");
    navigate("/signin");
  };

  return (
    <Fragment>
      <div id="header_top" className="header_top false">
        <div className="container">
          <div className="hleft">
            <a aria-current="page" className="header-brand active" href="/">
              <i className="fe fe-command brand-logo"></i>
            </a>
            <div className="dropdown"></div>
          </div>
          <div className="hright">
            <div className="dropdown">
              <div className="dropdown d-flex ">
                <a
                  href="/#"
                  className="nav-link icon mb-3 d-md-flex  btn-icon ml-1"
                  data-toggle="dropdown"
                >
                  <img
                    className="avatar"
                    src={userPng}
                    alt="User Avatar"
                    data-toggle="tooltip"
                    data-placement="right"
                  />
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/admin">
                    <i className="dropdown-icon fe fe-user"></i> Profile
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" onClick={(e) => logoutHandler()}>
                    <i className="dropdown-icon fe fe-log-out"></i> Sign out
                  </a>
                </div>
              </div>

              <p className="nav-link icon menu_toggle">
                <i className="fa  fa-align-left"></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="left-sidebar" className="sidebar ">
        <h5 className="brand-name">WEB-PM</h5>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <div className="">
            <ul className="metismenu">
              <li className="">
                <span className="g_heading">Directories</span>
              </li>

              <li className="">
                <span className="">
                  <Link aria-current="page" to="/admin">
                    <i className="icon-bar-chart"></i>Dashboard
                    <i className="caret-left"></i>
                  </Link>
                </span>
                {authPermissions.includes("Master-list") === true ? (<span className="">
                  <a aria-current="page" className="list-a" href="/events">
                    <i className="icon-rocket"></i>Master
                    <i className="caret-left"></i>
                  </a>
                </span>):""}

                <ul className="collapse collapse collapse collapse collapse collapse collapse collapse collapse collapse">
                  {authPermissions.includes("User Registration-list") ===
                  true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/usersregistration">
                          User Registration
                        </Link>
                      </span>
                    </li>
                   ) : null}
                  {authPermissions.includes("Clients-list") === true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/client">
                          Clients
                        </Link>
                      </span>
                    </li>
                  ) : null} 

                  {authPermissions.includes("Permissions-list") === true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/permission">
                          Permissions
                        </Link>
                      </span>
                    </li>
                  ) : null}

                  {authPermissions.includes("Role Permissions-list") ===
                  true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/rolepermission">
                          Role Permissions
                        </Link>
                      </span>
                    </li>
                  ) : null} 
                </ul>
              </li>

              <li className="">
              {authPermissions.includes("Project List-list") === true ? (
                <span className="">
                  <Link aria-current="page" to="/admin/project-list">
                    <i className="icon-notebook"></i>Projects
                    <i className="caret-left"></i>
                  </Link>
                </span>): null}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default SideBar;
