import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosPrivate from '../../../hooks/axiosPrivate';
import PermissionTableTd from './PermissionTableTd';
import { errorToast, successToast } from '../../../components/common/Toaster';
import AuthContext from '../../../context/Auth/AuthContext';

const UserPermission = () => {
    let { userId, roleId } = useParams();
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const [permissionDistinctName, setPermissionDistinctName] = useState([]);
    const [userPermissionsId, setUserPermissionsId] = useState([]);
    const { userDetail } = useContext(AuthContext);

    useEffect(() => {
        axiosPrivate
            .get('permissions')
            .then((fetched_permissions) => {
                setPermissions(fetched_permissions?.data?.data);
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }, [userPermissionsId]);

    useEffect(() => {
        axiosPrivate
            .get('permissions/distinct/name')
            .then((distinctName) => {
                setPermissionDistinctName(distinctName?.data?.data);
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }, []);

    useEffect(() => {
        axiosPrivate
            .get(`user-has-permissions/user/${userId}/${userDetail?.company_id}/${userDetail?.branch_id}`)
            .then((fetched_user_permissions) => {
                console.log(fetched_user_permissions);
                if (fetched_user_permissions.data.data.length) {
                    let userPermissionsArray = [];
                    for (let m = 0; m < fetched_user_permissions.data.data.length; m++) {
                        userPermissionsArray.push(fetched_user_permissions.data.data[m].permission_id);
                    }
                    setUserPermissionsId(userPermissionsArray);
                }
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }, [userId, userDetail]);

    const modifyPermissionIdArray = async (permission_id, action) => {
        if (action === false) {
            const index = userPermissionsId.indexOf(permission_id);
            userPermissionsId.splice(index, 1);
        } else {
            userPermissionsId.push(permission_id);
        }
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        let values = {
            user_id: userId,
            role_id: roleId,
            permission_ids: userPermissionsId,
            company_id: userDetail?.company_id,
            branch_id: userDetail?.branch_id
        };
        await axiosPrivate
            .post(`user-has-permissions`, values)
            .then((response) => {
                console.log(response);
                successToast(response.data.message);
                navigate('/admin/users');
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    if (error.response.status !== 201) {
                        errorToast(error.response.message);
                    }
                }
            });
    };


    const title = ['Create', 'Read', 'Update', 'Delete'];
    let checked = false;
    const rows = permissionDistinctName.map((distinct, index) => {
        return (
            <tr key={index}>
                <td>{distinct.name}</td>
                {permissions.map((permission, index1) => {
                    if (userPermissionsId.includes(permission.id)) {
                        checked = true;
                    } else {
                        checked = false;
                    }
                    if (permission.permission_name === distinct.name) {
                        if (permission.permission_action === 'Create') {
                            return (
                                <PermissionTableTd tdKey={index1} key={index1} id={permission.id} checked={checked} modifyPermissionIdArray={modifyPermissionIdArray} />
                            )
                        }
                        if (permission.permission_action === 'Read') {
                            return (
                                <PermissionTableTd tdKey={index1} key={index1} id={permission.id} checked={checked} modifyPermissionIdArray={modifyPermissionIdArray} />
                            )
                        }
                        if (permission.permission_action === 'Update') {
                            return (
                                <PermissionTableTd tdKey={index1} key={index1} id={permission.id} checked={checked} modifyPermissionIdArray={modifyPermissionIdArray} />
                            )
                        }
                        if (permission.permission_action === 'Delete') {
                            return (
                                <PermissionTableTd tdKey={index1} key={index1} id={permission.id} checked={checked} modifyPermissionIdArray={modifyPermissionIdArray} />
                            )
                        }
                    }

                    return true;
                })}
            </tr>
        )
    });

    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="tab-content mt-3">
                    <form onSubmit={submitHandler}>
                        <div
                            className="tab-pane fade show active"
                            id="user-permissions"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-header">
                                    <strong>User Permissions</strong>
                                </div>
                                <div className="card-body">
                                    <div className="row clearfix">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th />
                                                            {title.map((name, key) => (
                                                                <th key={key}>{name}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <button type="submit" className="btn btn-primary mr-2">
                                                SUBMIT
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                                onClick={() => navigate('/admin/users')}
                                            >
                                                CLOSE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserPermission;
