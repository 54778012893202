import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./AddTicketReply.module.css";
import Loader from "../../../context/Loader/Loader";
import LoaderContext from "../../../context/Loader/LoaderContext";
import classes from "../TicketList/TicketList.module.css";
import { successToast } from "../../../utils/Toaster";

const validationRules = {
  status: Yup.string().required("Status is required"),
  priority: Yup.string().required("Priority is required"),
  actual_time: Yup.string().required("Actual time is required"),
  // employee_user_id: Yup.string()
  // .required("Employee is required"),
};

const AddTicketReply = ({ setShowReplyComponent, ...props }) => {
  const { id } = useParams();
  const { fixNavbar, boxClose, box2Close, box3Close } = props;
  const [editorContent, setEditorContent] = useState("");
  const navigate = useNavigate();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [projectEmployees, setProjectEmployees] = useState([]);
  const [ticket, setTicket] = useState({});
  const [raiseByUserId, setRaiseByUserId] = useState("");
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [selectedButton, setSelectedButton] = useState("internal");
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const handleInternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("internal");
  };

  const handleExternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("external");
  };

  const handleProjectChange = (selectedProjectId) => {
    formik.handleChange("project_id")(selectedProjectId);
    fetchEmployeesByProjectId(selectedProjectId);
    setSelectedProjectId(selectedProjectId);
  };

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      setRaiseByUserId(userData?.data?.userId);
    }
  }, []);

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  function stripHtml(html) {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const fetchEmployeesByProjectId = async (project_id) => {
    try {
      const response = await axiosPrivate.get(
        `project/emp-by-project-id/${project_id}`
      );
      const employees = response?.data?.data || [];
      setProjectEmployees(employees);

      formik.setFieldValue("employee_user_id", []);
    } catch (error) {
    }
  };

  useEffect(() => {
    axiosPrivate
      .post(`tickets-by-task/${id}`)
      .then((response) => {
        const fetchedTicket = response.data.data;
        setTicket(fetchedTicket[0]);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("users")
      .then((response) => {
        const fetchedEmployeeOptions = response.data.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
          role_name: employee.role_name,
        }));
        setEmployeeOptions(fetchedEmployeeOptions);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    fetchEmployeesByProjectId(ticket?.project_id);
  }, [ticket]);

  const formik = useFormik({
    initialValues: {
      team_name: "",
      client_user_id: "",
      employee_user_id: [],
      priority: "",
      status: "",
      description: "",
      project_id: "",
      task_id:ticket?.task_id,
      actual_time: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const selectedEmployees = formik.values.employee_user_id;
      const cleanedDescription = stripHtml(editorContent);
      const requestData = {
        project_id: ticket?.project_id,
        subject: ticket?.subject,
        description: cleanedDescription,
        internal_external: selectedButton === "internal" ? 0 : 1,
        priority: ticket?.priority,
        status: values?.status,
        actual_time: values?.actual_time,
        user_id: selectedEmployees.includes("all")
          ? employeeOptions.map((employee) => employee.value)
          : selectedEmployees.map((employee) => employee.value),
        raised_by_user_id: raiseByUserId,
        task_id: ticket?.task_id,
        thread_id: ticket.thread_id,
      };

      try {
        const response = await axiosPrivate.post("ticket/raise", requestData);
        successToast("Reply Sent Successfully");
        window.location.reload();
        navigate(`/admin/project-ticket-list/view/${id}`);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            setFieldError(
              error.response.data.errors[i].param,
              error.response.data.errors[i].msg
            );
          }
        }
      }
    },
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body text-center ">
                <button
                  onClick={handleInternalButtonClick}
                  className={`btn btn-primary mr-2 mb-2 ${
                    selectedButton === "internal" ? "active" : ""
                  }`}
                >
                  Internal
                </button>
                <button
                  onClick={handleExternalButtonClick}
                  className={`btn btn-primary mb-2 ${
                    selectedButton === "external" ? "active" : ""
                  }`}
                >
                  External
                </button>

                {selectedButton === "internal" && (
                  <>
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                            required
                          >
                            <option value="" disabled>
                              Select Status *
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="Discarded">Discard</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                            required
                          >
                            <option value="" disabled>
                              Select Priority *
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="actual_time"
                            name="actual_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.actual_time}
                            required
                          >
                            <option value="" disabled>
                              Select Actual Time * (time to be taken to Complete
                              this task)
                            </option>
                            <option value="15">15Mins</option>
                            <option value="30">30Mins</option>
                            <option value="45">45Mins</option>
                            <option value="60">1Hrs</option>
                            <option value="75">1Hr 15Mins</option>
                            <option value="90">1Hr 30Mins</option>
                            <option value="105">1Hr 45Mins</option>
                            <option value="120">2Hrs</option>
                            <option value="135">2Hr 15Mins</option>
                            <option value="150">2Hr 30Mins</option>
                            <option value="165">2Hr 45Mins</option>
                            <option value="180">3Hrs</option>
                          </select>

                          {formik.touched.actual_time &&
                          formik.errors.actual_time ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.actual_time}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees.map((employee) => ({
                                value: employee.id,
                                label: employee.name,
                                role_name: employee.role_name,
                              })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                // Set employee_user_id to all employees for the current project
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                  }))
                                );
                              } else {
                                // Set employee_user_id to selected employees
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            required
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All "
                                : "Select Employee *"
                            }
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {selectedButton === "external" && (
                  <>
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                            required
                          >
                            <option value="" disabled>
                              Select Status *
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="Discarded">Discard</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                            required
                          >
                            <option value="" disabled>
                              Select Priority *
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="actual_time"
                            name="actual_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.actual_time}
                            required
                          >
                            <option value="" disabled>
                              Select Actual Time * (time to be taken to Complete
                              this task)
                            </option>
                            <option value="15">15Mins</option>
                            <option value="30">30Mins</option>
                            <option value="45">45Mins</option>
                            <option value="60">1Hrs</option>
                            <option value="75">1Hr 15Mins</option>
                            <option value="90">1Hr 30Mins</option>
                            <option value="105">1Hr 45Mins</option>
                            <option value="120">2Hrs</option>
                            <option value="135">2Hr 15Mins</option>
                            <option value="150">2Hr 30Mins</option>
                            <option value="165">2Hr 45Mins</option>
                            <option value="180">3Hrs</option>
                          </select>

                          {formik.touched.actual_time &&
                          formik.errors.actual_time ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.actual_time}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees.map((employee) => ({
                                value: employee.id,
                                label: employee.name,
                                role_name: employee.role_name,
                              })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                // Set employee_user_id to all employees for the current project
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                  }))
                                );
                              } else {
                                // Set employee_user_id to selected employees
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            required
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All "
                                : "Select Employee *"
                            }
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="form-group">
                    <ReactQuill
                      theme="snow"
                      value={editorContent}
                      required
                      onChange={handleEditorChange}
                    />
                  </div>

                  <div className="form-group">
                    <hr className="mt-4" />
                    <button type="submit" className="btn btn-primary">
                      Post Reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddTicketReply;
