import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { successToast } from "../../../components/common/Toaster";
import { errorToast } from "../../../utils/Toaster";

const Company = (props) => {

  const [clientState, setClientState] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);
  const headers = [
    { name: "CLIENT NAME", field: "name", sortable: true, classKey: "" },
    // { name: 'COMPANY NAME', field: 'name', sortable: true, classKey: '' },
    // { name: 'DESIGNATION', field: 'designation', sortable: true, classKey: '' },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    { name: "MOBILE", field: "mobile", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      .get("clients", options)
    
      .then((companies) => {
        // setAlertMessage({ message: "Clients fetched successfully" });

        const companiesData = companies.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/client/edit/${value.id}`}
                className="btn btn-icon btn-outline-info mr-1"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (true)
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => companyDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value; // console.log("uservalue",userData)
        });

        setClientState(companiesData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          errorToast(error?.response?.data?.message);
        }
      });
  }, []);

  const companyDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`clients/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setClientState([]);
          successToast("company deleted successfully");
          await refreshTable();
        }
      })
      .catch((error) => {
      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("clients", options)
        .then((companies) => {
          const companiesData = companies.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => companyDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setClientState(companiesData);
          resolve(true);
        })
        .catch((error) => {
          
        });
    });
  };

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div
              className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
            >
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="Employee-tab"
                        data-toggle="tab"
                        href="#Employee-list"
                      >
                        All
                      </a>
                    </li>
                    {/* <li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-view"
										>
											View
										</a>
									</li> */}
                    <li className="nav-item">
                      {/* <a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-Request"
										>
											Leave Request
										</a> */}
                    </li>
                  </ul>
                  <div className="header-action">
                    {/* <Link to="add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="section-body mt-3">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="user-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">CLIENT LIST</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          {
                            <DataTable2
                              lists={clientState}
                              headers={headers}
                              searchItems={searchItems}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Company;
