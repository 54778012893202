import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './UserRegistration.module.css';
import Select from 'react-select';
import { errorToast, successToast } from '../../../utils/Toaster';

const phoneRegExp =
/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	
	name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
	
	designation:  Yup.string()
    .max(120, "Designation must not exceed 120 characters")
    .required("Designation is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
	mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
	password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must not exceed 25 characters")
    .required("Password is required"),
};
const EditCompany = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});
	const [employeeOptions, setEmployeeOptions] = useState([]);
  const [roles, setRoles] = useState([]);
	
	
 	useEffect(() => {
		axiosPrivate
			.get(`users/${id}`)
			.then((response) => {
 				if (response.data.code == 200) {
					setGetData({...response.data.data});
				}
			})
			.catch((error) => {
				if (error.response) {
          errorToast(error?.response?.data?.message);
        }
			});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
 
	}, [getData]);

  useEffect(() => {
		axiosPrivate.get('roles').then((user_roles) => {
			setRoles(user_roles?.data?.data);
		});
	}, []);


	const formik = useFormik({
        initialValues: {
			name: "",
			email: "",
			role_id: "",
			mobile: "",
			designation: "",
			password: ""

		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
            let formData = new FormData();
		
			formData.append("name", values.name);
			formData.append("role", values.role_id);
			formData.append("mobile", values.designation);
			formData.append("email", values.email);
			formData.append("mobile", values.mobile);
			formData.append("password", values.password);
			axiosPrivate
				.put(`users/${id}`, values)
				.then((response) => {
					successToast('users updated successfully' );
					navigate('/admin/usersregistration');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Users</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
									<div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                         Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder=" Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                          Role:<span className="required">*</span>
                        </label>
                      <div className="form-group">
                        <select
                          className="form-control show-tick"
                          id="role_id"
                          name="role_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.role_id}
                        >
                          <option>Select Role</option>
                          {roles.map((role, key) => (
                            <option key={key + role.name} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.role_id&& formik.errors.role_id ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.role_id}
                          </div>
                        ) : null}
                      </div>
                    </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                        Designation:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            placeholder="Designation *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.designation}
                          />
                          {formik.touched.designation && formik.errors.designation ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email ID * "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
						   {formik.touched.email &&
													formik.errors.email ? (
														<div className={classes.invalidDataError}>
															{formik.errors.email}
														</div>
													) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Mobile Number:
                          <span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="number"
                            className="form-control"
                            placeholder=" Mobile Number "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                          />
                          {formik.touched.mobile &&
													formik.errors.mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.mobile}
														</div>
													) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Password:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>


										<div className="col-12">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/usersregistration')}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditCompany;
