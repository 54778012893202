import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './TaskAssign.module.css';

const validationRules = {
	// post_name: Yup.string()
	// 	.max(120, 'Name must not exceed 120 characters')
	// 	.required('Name is required'),
	// id: Yup.string().required('Role is required'),
};

const AddTaskAssign = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [projects, setProjects] = useState([]);
	const [team, setTeam] = useState([]);
	const [employeeOptions, setEmployeeOptions] = useState([]);

	useEffect(() => {
		axiosPrivate
		  .get('team')
		  .then((team) => {
			setTeam(team?.data?.data);
		  })
		  .catch((error) => {
			if (error.response) {
			  console.log(error.response.status);
			} else if (error.request) {
			  console.log(error.request);
			} else {
			  console.log(error.message);
			}
		  });
	  }, []);

	  useEffect(() => {
   
		axiosPrivate
		.get('users')
		.then((response) => {
		  const fetchedEmployeeOptions = response.data.data.map((employee) => ({
			value: employee.id,
			label: employee.name,
		  }));
		  setEmployeeOptions(fetchedEmployeeOptions);
		  console.log(fetchedEmployeeOptions);
		})
		.catch((error) => {
		  console.error('Error fetching employee names:', error);
		});
	
		axiosPrivate
		  .get('projects')
		  .then((projects) => {
			setProjects(projects?.data?.data);
		  })
		  .catch((error) => {
			if (error.response) { 
			  console.log(error.response.status);
			} else if (error.request) {
			  console.log(error.request);
			} else {
			  console.log(error.message);
			}
		  });
	  }, []);
	

	

	const formik = useFormik({
		initialValues: {
			
			project_id: '',
			team_id: '',
			assigned_to_user_id: '',
			estimated_hours: ''
		
		  },
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			const requestData = {
				team_id: values.team_id,
				project_id: values.project_id,
				assigned_to_user_id: values.assigned_to_user_id,
				estimated_hours: values.estimated_hours,
			  };
			axiosPrivate
				.post('task-assign', requestData)
				.then((response) => {
					setAlertMessage({ message: 'Task assign created successfully' });
					navigate('/admin/taskassign');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Task Assign</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
										<div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Project Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="project_id"
                            name="project_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.project_id}
                          >
                            <option>Select Project</option>
                            {projects.map((project, key) => (
                              <option
                                key={key + project.title}
                                value={project.id}
                              >
                                {project.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.project_id && formik.errors.project_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.project_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                                     
					  <div className="col-lg-6 col-md-6 col-sm-12">
					  <label>
                          Team Name:<span className="required">*</span>
                        </label>
<div className="form-group">
	<select
		className="form-control show-tick"
		id="team_id"
		name="team_id"
		onChange={formik.handleChange}
		onBlur={formik.handleBlur}
		value={formik.values.team_id}
	>
		<option>Select Team</option>
		{team.map((team, key) => (
			<option
				key={key + team.team_name}
				value={team.id}
			>
				{team.team_name}
			</option>
		))}
	</select>
	{formik.touched.team_id && formik.errors.team_id ? (
		<div className={classes.invalidDataError}>
			{formik.errors.team_id}
		</div>
	) : null}
</div>

</div>

{/* <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Employee Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            isMulti
                            options={employeeOptions}
                            onChange={(selectedOptions) =>
                              formik.setFieldValue('employee_user_id', selectedOptions)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                          />
                          {formik.touched.employee_user_id && formik.errors.employee_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div> */}



<div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Employee Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="assigned_to_user_id"
                            name="assigned_to_user_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.assigned_to_user_id}
                          >
                              <option>Select Employee</option>
            {employeeOptions.map((employee) => (
                <option key={employee.value} value={employee.value}>
                    {employee.label}
                </option>
            ))}
        </select>
        {formik.touched.assigned_to_user_id && formik.errors.assigned_to_user_id ? (
            <div className={classes.invalidDataError}>
                {formik.errors.assigned_to_user_id}
            </div>
        ) : null}
                        </div>
                      </div>

					  <div className="col-lg-6 col-md-12 col-sm-12">
										<label>
                        Time:<span className="required">*</span>
                        </label>
											<div className="form-group">
												<input
													id="estimated_hours"
													name="estimated_hours"
													type="number"
													className="form-control"
													placeholder="Time(hours) *"
                         	 						onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.estimated_time}
												/>
												{formik.touched.estimated_hours && formik.errors.estimated_hours? (
													<div className={classes.invalidDataError}>{formik.errors.estimated_hours}</div>
												) : null}
											</div>
										</div>

											<div className="col-12">
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/taskassign')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddTaskAssign;
