import { useRef } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useContext, useEffect, useState } from "react";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";

const TicketList = (props) => {
  const sparkline2 = useRef(null);
  const sparkline3 = useRef(null);
  const sparkline4 = useRef(null);


  const [ticketState, setTicketState] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);

  const headers = [
    { name: "EMPLOYEE NAME", field: "name", sortable: true, classKey: "w60" },
    {
      name: "PROJECT NAME",
      field: "project_name",
      sortable: true,
      classKey: "",
    },
    {
      name: "SHORT DESCRIPTION",
      field: "description",
      sortable: true,
      classKey: "",
    },
    { name: "PRIORITY", field: "priority", sortable: true, classKey: "" },
    { name: "DEPARTMENT", field: "department", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["project_name", "priority"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      .get("companies", options)
      .then((companies) => {
        
        const companiesData = companies.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/company/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (true)
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => employeeDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
          setIsLoading(false);
        });

        setTicketState(companiesData);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const employeeDeleteHandler = async (id) => {
    setIsLoading(true);
    axiosPrivate
      .delete(`companies/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setTicketState([]);
          successToast("company deleted successfully");
          await refreshTable();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorToast(error?.response?.data?.message ||"Something went wrong!");
      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      setIsLoading(true);
      axiosPrivate
        .get("companies", options)
        .then((companies) => {
          const companiesData = companies.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => employeeDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
            
          });
          setTicketState(companiesData);
          resolve(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    });
  };

  return (
    <>
     <div>
    {loading ? (<Loader/>) : (
      <div>
          <div
            className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
          >
            <div className="container-fluid">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul className="nav nav-tabs page-header-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-list"
                    >
                      All
                    </a>
                  </li>
                </ul>
                <div className="header-action">
                  <Link to="add" className="text-decoration-none">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <i className="fe fe-plus mr-2" />
                      Add
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div
                  className="tab-pane fade show active"
                  id="user-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">TICKET LIST</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        {
                          <DataTable2
                            lists={ticketState}
                            headers={headers}
                            searchItems={searchItems}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> )}
      </div>
    </>
  );
};

export default TicketList;
