import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import slider1 from '../../assets/images/slider1.svg';
import slider2 from '../../assets/images/slider2.svg';
import slider3 from '../../assets/images/slider3.svg';
import { useNavigate } from "react-router-dom";
import AuthContext from '../../context/Auth/AuthContext';
import axiosApiSigninInstance from '../../hooks/axiosLogin';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SignIn = () => {
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const loginSchma = Yup.object({
        email: Yup.string().email().required("Email is Required"),
        password: Yup.string().required(" Password is Required"),
    })

    const loginForm = {
        email: "",
        password: "",
    };

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: loginForm,
        validationSchema: loginSchma,
        onSubmit: ((data, values) => {
            console.log('login values:', values);
            axiosApiSigninInstance.post('login', data)
                .then((res) => {
                    localStorage.setItem('userData', JSON.stringify(res.data));
                    localStorage.setItem('userDetails', JSON.stringify(res.data));
                    localStorage.setItem('userRefreshToken', JSON.stringify(res?.data?.refresh_token));
                    console.log(res,res.data);
                    setUser(res.data);
                    navigate('/admin');
                })
                .catch((err) => {
                    console.log(err);
                })
        })

    })

    useEffect(() => {
        if (user.token) {
            navigate("/admin", { replace: true });
            console.log(user.token)
        }
    }, [user]);


    return (
        <>
            <div className="auth_left">
                <div className="card">
                    <div className="text-center mb-2">
                        <Link className="header-brand" to="/">
                            <i className="fe fe-command brand-logo" />
                        </Link>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="card-title">Login to your account</div>

                            <div className="form-group">
                                <label className="form-label">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    id="id"
                                    className="form-control"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Email"
                                />
                                {touched.email && errors.email ? (
                                    <div style={{ color: 'red', fontSize: '12px' }}>
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group">
    <label className="form-label">
        Password
        <Link className="float-right small" to="/forgotpassword">
            I forgot password
        </Link>
    </label>
    <input
        type="password"
        name="password"
        id="password"
        className="form-control"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Password"
    />
    {touched.password && errors.password ? (
        <div style={{ color: 'red', fontSize: '12px' }}>
            {errors.password}
        </div>
    ) : null}
    {touched.password && !errors.password && values.password !== "correctPassword" && (
        <div style={{ color: 'red', fontSize: '12px' }}>
            Invalid password. Please try again.
        </div>
    )}
</div>

                            <div className="form-group">
                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">Remember me</span>
                                </label>
                            </div>
                            <div className="form-footer">
                                <input type="submit" className="btn btn-primary btn-block" value="login" />
                            </div>
                        </form>
                    </div>
                    {/* <div className="text-center text-muted">
                        Don't have account yet? <Link to="/signup">Sign Up</Link>
                    </div> */}
                </div>
            </div>
            <div className="auth_right">
                <div className="carousel slide" data-ride="carousel" data-interval={3000}>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={slider1} className="img-fluid" alt="login page" />
                            <div className="px-4 mt-4">
                                <h4>Fully Responsive</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={slider2} className="img-fluid" alt="login page" />
                            <div className="px-4 mt-4">
                                <h4>Quality Code and Easy Customizability</h4>
                                <p>There are many variations of passages of Lorem Ipsum available.</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={slider3} className="img-fluid" alt="login page" />
                            <div className="px-4 mt-4">
                                <h4>Cross Browser Compatibility</h4>
                                <p>Overview We're a group of women who want to learn JavaScript.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignIn;