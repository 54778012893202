import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './ExpensesTypeStyle.module.css';

 
const validationRules = {
	expense_type: Yup.string()
		.max(100, 'Name must not exceed 100 characters')
		.required('Expenses Type is required'),
};

const AddExpensesType = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			expense_type: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.post('expensetypes/create', values)
				.then((response) => {
					// alert("response");
					setAlertMessage({ message: 'New department created successfully' });
					navigate('/admin/expensetypes');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/expensetypes', add: '/admin/expensetypes/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Expenses Type</strong>
									</div>

									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="expense_type"
														type="text"
														className="form-control"
														placeholder="Expenses Type*"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.expense_type}
													/>
													{formik.touched.expense_type &&
													formik.errors.expense_type ? (
														<div className={classes.invalidDataError}>
															{formik.errors.expense_type}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-12">
												<hr className="mt-4" />
												<div className="table-responsive">
													<table className="table table-striped">
														<tbody></tbody>
													</table>
												</div>
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/expensetypes')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddExpensesType;
