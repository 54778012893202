import { useFormik } from 'formik';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './addUserStyle.module.css';
import { successToast } from '../../../components/common/Toaster';
import AuthContext from '../../../context/Auth/AuthContext';

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	mobile: Yup.string()
		.required('Mobile is required')
		.matches(phoneRegExp, 'Mobile number is not valid')
		.min(10, 'Mobile number must be of 10 digits')
		.max(10, 'Mobile number must be of 10 digits'),
	role: Yup.string().required('Role is required'),
	address: Yup.string()
		.max(200, 'Address must not exceed 200 characters')
		.required('Address is required'),
};

const EditUser = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const { checkPermissionExists } = useContext(AuthContext);
	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		mobile: '',
		role: '',
		address: '',
		city: ' ',
		state: ' ',
		zip: ' ',
	});

	useEffect(() => {
		axiosPrivate
			.get('roles')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get(`users/${id}`)
			.then((response) => {
				if (response.data.code === 200) {
					formik.setValues({ ...response.data.data });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [id]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.put(`users/${id}`, values)
				.then((response) => {
					successToast(response.data.message);
					navigate('/admin/users');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status !== 200) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit User</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-4 col-md-4 col-sm-4">
											<div className="form-group">
												<label className="form-label">
													Name <span style={{ color: "red" }}>*</span>
												</label>
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Name"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													Email <span style={{ color: "red" }}>*</span>
												</label>
												<input
													id="email"
													name="email"
													type="text"
													className="form-control"
													placeholder="Email ID"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div className={classes.invalidDataError}>
														{formik.errors.email}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													Mobile <span style={{ color: "red" }}>*</span>
												</label>
												<input
													id="mobile"
													name="mobile"
													type="text"
													className="form-control"
													placeholder="Mobile No"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.mobile}
												/>
												{formik.touched.mobile && formik.errors.mobile ? (
													<div className={classes.invalidDataError}>
														{formik.errors.mobile}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													Role <span style={{ color: "red" }}>*</span>
												</label>
												<select
													className="form-control"
													id="role"
													name="role"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.role}
												>
													<option>Select Role Type</option>
													{roles.map((role, key) => (
														<option key={key + role.name} value={role.name}>
															{role.name}
														</option>
													))}
												</select>
												{formik.touched.role && formik.errors.role ? (
													<div className={classes.invalidDataError}>
														{formik.errors.role}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													Address
												</label>
												<input
													id="address"
													name="address"
													type="text"
													className="form-control"
													placeholder="address"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.address}
												/>
												{formik.touched.address && formik.errors.address ? (
													<div className={classes.invalidDataError}>
														{formik.errors.address}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													City
												</label>
												<input
													id="city"
													name="city"
													type="text"
													className="form-control"
													placeholder="city"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.city}
												/>
												{formik.touched.city && formik.errors.city ? (
													<div className={classes.invalidDataError}>
														{formik.errors.city}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													State
												</label>
												<input
													id="state"
													name="state"
													type="text"
													className="form-control"
													placeholder="state"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.state}
												/>
												{formik.touched.state && formik.errors.state ? (
													<div className={classes.invalidDataError}>
														{formik.errors.state}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-md-4 col-sm-12">
											<div className="form-group">
												<label className="form-label">
													Zip Code
												</label>
												<input
													id="zip"
													name="zip"
													type="number"
													className="form-control"
													placeholder="zip"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.zip}
												/>
												{formik.touched.zip && formik.errors.zip ? (
													<div className={classes.invalidDataError}>
														{formik.errors.zip}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12">
											{checkPermissionExists('Create-User') && (
												<button type="submit" className="btn btn-primary">
													SUBMIT
												</button>
											)}
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/users')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditUser;
