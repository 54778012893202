import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../TodoList/CustomDataTable";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { FaEye } from "react-icons/fa";
import { successToast, errorToast } from "../../../utils/Toaster";

const TodoList = (props) => {
  const [postTypedata, setPostTypedata] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  const headers = [
    { name: "PROJECT NAME" },
    { name: "Task Title" },
    {
      name: "Category | Sub Category",
    },
    {
      name: "Assignee",
    },
    {
      name: "Status",
    },
    { name: "DUE DATE" },
    { name: "PRIORITY" },
    { name: "ACTION" },
  ];

  const searchItems = ["title"];

  const handleCheckboxChange = (taskId) => {
    if (completedTasks.includes(taskId)) {
      // If task is already completed, remove it from the completedTasks state
      setCompletedTasks(completedTasks.filter((id) => id !== taskId));
    } else {
      // If task is not completed, add it to the completedTasks state
      setCompletedTasks([...completedTasks, taskId]);
    }
  };

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);
    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("tasks", options)
      .then((posttypes) => {
        const posttypeData = posttypes.data.data.map((value) => {
          value["new"] = value?.priority;
          let buttons = [];
          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/project-taskboard/edit/${value.id}`}
              className="btn btn-icon btn-outline-info mr-1 mb-1"
              title="Edit"
            >
              <i className="fa fa-edit"></i>
            </Link>
          );

          buttons.push(
            <Link
              key="viewButton##1"
              type="button"
              to={`/admin/project-ticket-list/view/${value?.id}`}
              className="btn btn-icon btn-outline-info mr-1 mb-1"
              title="View"
            >
              <FaEye />
            </Link>
          );

          value["priority"] = (
            <div>
              {value.priority === "4" && (
                <button
                  className="btn"
                  style={{
                    color: "white",
                    background: "#C70039",
                    minWidth: "105px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#C70039")
                  }
                >
                  Critical
                </button>
              )}
              {value.priority === "3" && (
                <button
                  className="btn"
                  style={{
                    color: "white",
                    background: "#FF6969",
                    minWidth: "105px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "redrgb(255, 114, 114")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#FF6969")
                  }
                >
                  High
                </button>
              )}
              {value.priority === "1" && (
                <button
                  className="btn"
                  style={{
                    color: "white",
                    background: " #41db41",
                    minWidth: "105px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "green")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#41db41")
                  }
                >
                  Low
                </button>
              )}
              {value.priority === "2" && (
                <button
                  className="btn"
                  style={{
                    color: "white",
                    background: "#ffc458",
                    minWidth: "105px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "orange")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#ffc458")
                  }
                >
                  Medium
                </button>
              )}
            </div>
          );
          value["due_date"] = formatDate(value["due_date"]);
          value["action"] = buttons.length > 0 ? buttons : "-";
          const isCompleted = completedTasks.includes(value.id);
          const textStyle = isCompleted
            ? { textDecoration: "line-through" }
            : {};
          return {
            ...value,
            style: textStyle,
          };
        });

        setPostTypedata(posttypeData);
      })
      .catch((error) => {
      });
  }, [completedTasks]);

  const postTypeDataDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`post-type/${id}`)
      .then(async (response) => {
        if (response.data.code === 200) {
          setPostTypedata([]);
          successToast("post type deleted successfully");
          await refreshTable();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorToast(error?.response?.data?.message||"Something went wrong!");
        }
      });
  };

  const handleTaskCompletion = (taskId) => {
    if (completedTasks.includes(taskId)) {
      setCompletedTasks(completedTasks.filter((id) => id !== taskId));
    } else {
      setCompletedTasks([...completedTasks, taskId]);
    }
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("post-type", options)
        .then((posttype) => {
          const posttypeData = posttype.data.data.map((value) => {
            let buttons = [];
            buttons.push(
              <button
                key="editButton##1"
                type="button"
                className="btn btn-icon"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </button>
            );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setPostTypedata(posttypeData);
          resolve(true);
        })
        .catch((error) => {
        });
    });
  };

  return (
    <>
      <div>
        <div>
          <div
            className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="nav nav-tabs page-header-tab">
                {/* <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-list"
                    >
                      All
                    </a>
                  </li> */}
                <li className="nav-item">
                  {/* <a
                      className="nav-link"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-view"
                    >
                      View
                    </a> */}
                </li>
                <li className="nav-item">
                  {/* <a
                      className="nav-link"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-Request"
                    >
                      Leave Request
                    </a> */}
                </li>
              </ul>
              <div className="header-action"></div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> TODAY'S TODO</h3>
                  </div>
                  <div className="card-body">
                    {/* Use the CustomTable component */}
                    <CustomTable
                      userName={props?.userName}
                      headers={headers}
                      data={postTypedata}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodoList;
