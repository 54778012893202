import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { errorToast, successToast } from "../../../utils/Toaster";

const ProjectTaskList = (props) => {
  const [projects, setProjects] = useState([]);

  const headers = [
  
    { name: "Name", field: "name", sortable: true, classKey: "w60" },
    { name: "Action", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("task-categories/categories", options)
      .then((projects) => {
        const projectData = projects.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/project-task-list/edit/${value.id}`}
                className="btn btn-icon btn-outline-info mr-1"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (true)
            buttons.push(
              <button
                key="[nodemon] app crashed - waiting for file changes before starting...leteButton##1"
                type="button"
                data-id={value.id}
                // onClick={() => taskDeleteHandler(value.task_name)}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });
        setProjects(projectData);
      })
      .catch((error) => {
      });
  }, []);

  const projectDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`users/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setProjects([]);
          successToast("User deleted successfully");
          await refreshTable();
        }
      })
      .catch((error) => {
    errorToast(error?.response?.data?.message||"Something went wrong!");
      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("projects", options)
        .then((projects) => {
          const projectData = projects.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => projectDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setProjects(projectData);
          resolve(true);
        })
        .catch((error) => {
        });
    });
  };

  return (
    <>
      <div>
        <div>
          <div
            className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}
          >
            <div className="container-fluid">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul className="nav nav-tabs page-header-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-list"
                    >
                      All
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      id="Employee-tab"
                      data-toggle="tab"
                      href="#Employee-view"
                    >
                      View
                    </a>
                  </li> */}
                  <li className="nav-item">
                    {/* <a
										className="nav-link"
										id="Employee-tab"
										data-toggle="tab"
										href="#Employee-Request"
									>
										Leave Request
									</a> */}
                  </li>
                </ul>
                <div className="header-action">
                  <Link to="add" className="text-decoration-none">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <i className="fe fe-plus mr-2" />
                      Add
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div
                  className="tab-pane fade show active"
                  id="user-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">TASK LIST</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        {
                          <DataTable2
                            lists={projects}
                            headers={headers}
                            searchItems={searchItems}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectTaskList;
