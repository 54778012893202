import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Client.module.css";
import { successToast } from "../../../utils/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationRules = {
  client_name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  company_name: Yup.string()
    .max(120, "Company Name must not exceed 120 characters")
    .required("Company Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must not exceed 25 characters")
    .required("Password is required"),
};


const EditCompany = () => {
  let { id } = useParams();
  const navigate = useNavigate();
	const {loading ,setIsLoading} = useContext(LoaderContext);

  useEffect(() => {
		setIsLoading(true);
    axiosPrivate
      .get(`users/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          formik.setFieldValue("client_name", response.data?.data?.name);
					formik.setFieldValue("company_name", response.data?.data?.company_name);
					formik.setFieldValue("email", response.data?.data?.email);
					formik.setFieldValue("mobile", response.data?.data?.mobile);
        }
				setIsLoading(false);
      })
      .catch((error) => {
				setIsLoading(false);
			});
  }, []);

  const formik = useFormik({
    initialValues: {
      company_name: "",
      email: "",
      client_name: "",
      mobile: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      let formData = new FormData();

      formData.append("client_name", values.client_name);
      formData.append("company_name", values.company_name);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      formData.append("password", values.password);
      axiosPrivate
        .put(`companies/${id}`, values)
        .then((response) => {
          successToast("Client Information updated !");
          navigate("/admin/client");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
		<> {
			loading ? <Loader /> :(
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Edit Company</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label>
                        Client Name:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="client_name"
                          name="client_name"
                          type="text"
                          className="form-control"
                          placeholder="Client Name *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_name}
                        />
                        {formik.touched.client_name &&
                        formik.errors.client_name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.client_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label>
                        Company Name:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="company_name"
                          name="company_name"
                          type="text"
                          className="form-control"
                          placeholder="Company Name *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.company_name}
                        />
                        {formik.touched.company_name &&
                        formik.errors.company_name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.company_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label>
                        Email:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email ID *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />

                        {formik.touched.email && formik.errors.email ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label>
                        Mobile Number:
                        <span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="mobile"
                          name="mobile"
                          type="number"
                          className="form-control"
                          placeholder=" Mobile Number "
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label>
                        Password:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Password *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/client")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div> )}
		</>
  );
};

export default EditCompany;
