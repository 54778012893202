import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { FaEye } from 'react-icons/fa';
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";

const TicketDeatils = (props) => {
  const [ticket, setTicket] = useState([]);
  const [user, setUser] = useState("");
  const {loading,setIsLoading} = useContext(LoaderContext);
  
  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      setUser(userData?.data);
    }
  }, []);

  const headers = [
    { name: "PROJECT NAME", field: "title", sortable: true, classKey: "" },

    {
      name: "RAISED BY",
      field: "raised_by_user",
      sortable: true,
      classKey: "",
    },
    { name: "Subject", field: "subject", classKey: "" },
    { name: "Status", field: "status", sortable: true, classKey: "" },
    { name: "PRIORITY", field: "priority", sortable: true, classKey: "" },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["title"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };

    setIsLoading(true);
    axiosPrivate
      .get("tickets", options)
      .then((task) => {
        const taskData = task.data.data.map((value, key) => {
          let buttons = [];

          if (true)
            buttons.push(
              <Link
                key="viewButton##1"
                type="button"
                to={`/admin/project-ticket-list/view/${value.id}`}
                className="btn btn-icon btn-outline-success"
                title="View"
              >
                <FaEye />
              </Link>
            );
            value["priority"] =
            value?.priority == 4 ? "Critical" :
            value?.priority == 3 ? "High" :
            value?.priority == 2 ? "Medium" :
            value?.priority == 1 ? "Low" : "";
            value["action"] = buttons.length > 0 ? buttons : "-";
          return value; // console.log("uservalue",userData)
        });

        setTicket(taskData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const taskDeleteHandler = async (task_name) => {
    console.log("name", task_name);
    setIsLoading(true);
    axiosPrivate
      .delete(`task-type/${task_name}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setTicket([]);
          successToast("task deleted successfully");
          await refreshTable();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message||"Something went wrong!");
        setIsLoading(false);

      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      setIsLoading(true);
      axiosPrivate
        .get("team", options)
        .then((task) => {
          const taskData = task.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  // to={`/admin/team/edit/${value.id}`}
                  className="btn btn-icon btn-outline-info mr-1"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => taskDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert btn-outline-danger"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setTicket(taskData);
          resolve(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    });
  };


  // const filteredTicket = ticket.filter(
  //   (ticket) =>
  //     ticket?.raised_by_user_id === user?.userId ||
  //     ticket.assigned_users.some((assignedUser) => assignedUser?.role_id === user?.role)
  // );
  
  return (
    <div>
    {loading ? <Loader/> :(
      <div>
        <div className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="Employee-tab"
                    data-toggle="tab"
                    href="#Employee-list"
                  >
                    All
                  </a>
                </li>
                {/* <li className="nav-item">
										<a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-view"
										>
											View
										</a>
									</li> */}
                <li className="nav-item">
                  {/* <a
											className="nav-link"
											id="Employee-tab"
											data-toggle="tab"
											href="#Employee-Request"
										>
											Leave Request
										</a> */}
                </li>
              </ul>
              <div className="header-action">
                <Link to="add" className="text-decoration-none">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <i className="fe fe-plus mr-2" />
                    Add
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">TICKET LIST</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={ticket}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> )}
    </div>
  );
};

export default TicketDeatils;
