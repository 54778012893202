import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Branch.module.css';
const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required(' branch name is required'),
	id: Yup.string().required('Role is required'),
};

const EditBranch = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});

	useEffect(() => {
		axiosPrivate
			.get('companies')
			.then((companies) => {
				// console.log("compaines",companies.data.data)
				setRoles(companies?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get(`branches/${id}`)
			.then((response) => {
 
				if (response.data.code == 200) {
					setGetData({ ...response.data.data });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [roles]);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

 
	const formik = useFormik({
		initialValues: {
			name: '',
			id: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
            var values={
                company_id:values.id,
                name:values.name
            }
 			axiosPrivate
				.put(`branches/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'branches updated successfully' });
					navigate('/admin/branch');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							console.log('branches error');

							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Branch</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<select
													className="form-control show-tick"
													id="role"
													name="id"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.id}
												>
													<option>Select Company</option>
													{roles.map((role, key) => (
														<option key={key + role.name} value={role.id}>
															{role.name}
														</option>
													))}
												</select>
												{formik.touched.id && formik.errors.id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.id}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Branch Name *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12">
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/branch')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditBranch;
