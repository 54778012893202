import axios from 'axios';
import { axiosPublic } from './axiosPublic';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  }
});

const userToken = JSON.parse(localStorage.getItem('userData') || '{}');

axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${userToken.token||userToken?.data?.token}`,
      'Accept': 'application/json',
    }
    return config;
  },
  error => {
   
  });

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();
    const newToken = JSON.parse(localStorage.getItem('userData') || '{}');
    axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken.token || newToken?.data?.token}`;
    console.log("token1", axiosApiInstance.defaults.headers.common['Authorization']);
    originalRequest.headers.Authorization = `Bearer ${newToken.token}`;
    console.log("token2", originalRequest);
    return axiosApiInstance(originalRequest);
  }
  return ;
});

const refreshAccessToken = async () => {
  return new Promise(async (resolve, reject) => {
    const refreshToken = JSON.parse(localStorage.getItem('userData') || '{}');
    console.log(refreshToken)
    await axiosPublic.post('refresh-token', { refresh_token: refreshToken.token || refreshToken?.data?.token})
      .then((response) => {
        localStorage.setItem('userData', JSON.stringify(response));
        
        resolve(response.data);
      }, (error) => {
        
      });
  });
};

export default axiosApiInstance;