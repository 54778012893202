import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import TaskList from "../../../components/TaskList";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectTaskList.module.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { FaCheck, FaTimes } from "react-icons/fa";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { successToast } from "../../../utils/Toaster";

const validationRules = {
  project_id: Yup.string().required("Project is required"),
  task: Yup.array()
    .of(
      Yup.object().shape({
        category_id: Yup.string().required("Category is required"),
        sub_category: Yup.string().required("Sub Category is required"),
        title: Yup.string().required("Activity/Task Title is required"),
        assigned_to_user_id: Yup.string().required("Employee Name is required"),
        priority: Yup.string().required("Priority is required"),
        estimated_time: Yup.number()
          .required("Estimated Hours is required")
          .positive("Estimated Hours must be a positive number"),
        due_date: Yup.string().required("Date is required"),
      })
    )
    .required("At least one task is required"),
};

const AddTask = ({ project_id }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const originalDateString = "2023-12-14T05:00:00.000";
  const formattedDate = formatDate(originalDateString);

  let userId = "";
  if (localStorage.getItem("userData")) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userId = userData?.data?.userId;
  }

  const [tasklist, setTaskList] = useState([
    {
      category_id: "",
      sub_category: "",
      title: "",
      assigned_to_user_id: "",
      estimated_time: "",
      priority: "",
      status: "",
      due_date: formatDate(date),
      parentListItems: [],
      childList: [],
      raised_by_user_id: userId,
      status: "",
    },
  ]);

  const [projects, setProjects] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [category, setCategory] = useState([]);

  const handleProjectChange = async (event) => {
    const selectedProjectId = event.target.value;

    try {
      const teamData = await axiosPrivate.post(`get-team-by-project`, {
        project_id: selectedProjectId,
      });

      if (teamData?.data?.data && teamData?.data?.data.length > 0) {
        setEmployee(JSON.parse(teamData?.data?.data[0]?.employee_details));
      } else {
        setEmployee([]);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    handleProjectChange({ target: { value: project_id } });
  }, [project_id]);

  useEffect(() => {
    axiosPrivate
      .get("projects")
      .then((projects) => {
        setProjects(projects?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("task-categories/categories")
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    if (category.length > 0) {
      const oldTasks = formik.values.task;
      for (let i = 0; i < oldTasks.length; i++) {
        oldTasks[i].parentListItems = category;
      }
      formik.setFieldValue("task", oldTasks);
    }
  }, [category]);

  useEffect(() => {
    if (tasklist.length > 0 && category.length > 0) {
      let oldTaskList = tasklist;
      for (let i = 0; i < tasklist.length; i++) {
        oldTaskList[i]["parentListItems"] = category;
      }
      setTaskList(oldTaskList);
    }
  }, [category]);

  const formik = useFormik({
    initialValues: {
      project_id: project_id || "",
      task: tasklist,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const tasks = values.task.map((task) => {
        const { parentListItems, childList, ...restOfTask } = task;
        return restOfTask;
      });
      const postData = {
        project_id: values.project_id,
        task: tasks,
      };

      try {
        const response = await axiosPrivate.post(`task-assign`, postData);
        setFormSubmitted(true);
        successToast("Project added successfully");

        navigate("/admin/project-list");
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Function to add a new parent task
  const addNewParentTask = (eve) => {
    eve.preventDefault();
    const newParentTask = {
      category_id: "",
      sub_category: "",
      title: "",
      assigned_to_user_id: "",
      status: "",
      estimated_time: "",
      priority: "",
      due_date: formatDate(date),
      parentListItems: category,
      childList: [],
      raised_by_user_id: userId,
      status: "",
    };
    formik.setFieldValue("task", [...formik.values.task, newParentTask]);
  };

  // Function to remove a parent task
  const removeParentTask = (ind, eve) => {
    eve.preventDefault();
    if (formik.values.task.length > 1) {
      const updatedtasklist = [...formik.values.task];
      updatedtasklist.splice(ind, 1);
      formik.setFieldValue("task", updatedtasklist);
    }
  };

  const setFormikCategory = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.category_id]`, value);
    await getChildOptions(value, parentIndex);
    return true;
  };

  const setFormikChildCategory = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.sub_category]`, value);
    return true;
  };

  const setFormikEmployee = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.assigned_to_user_id]`, value);
    return true;
  };

  const getChildOptions = async (taskId, index) => {
    try {
      const response = await axiosPrivate.get(
        `task-categories/sub-categories/${taskId}`
      );
      if (response.data.code === 200 && response?.data?.data) {
        formik.setFieldValue(`task[${index}.childList]`, response?.data?.data);
        // setTeam(updatedList);
      } else {
        formik.setFieldValue(`task[${index}.childList]`, []);
      }
    } catch (error) {
    }
  };

  // Task

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Add Project Tasks</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix"></div>
                  <div>
                    {/* Task Table */}
                    {formik.values.task.length > 0 &&
                      formik.values.task.map((item, index) => (
                        <table
                          className="table-bordered"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <thead style={{ display: "flex", width: "100%" }}>
                            <tr style={{ display: "flex", width: "100%" }}>
                              <th style={{ width: "85%" }}>
                                <div className="form-group">
                                  <label>Category {index + 1}</label>
                                  <select
                                    className="form-control show-tick"
                                    id="category_id"
                                    name="category_id"
                                    onChange={(event) =>
                                      setFormikCategory(
                                        event,
                                        event.target.value,
                                        index
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.task[index].category_id
                                    }
                                    required
                                    disabled={formSubmitted}
                                  >
                                    <option value="" disabled>
                                      Select Category
                                    </option>
                                    {item?.parentListItems.map((task, ind) => (
                                      <option key={ind} value={task.id}>
                                        {task.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </th>

                              <th style={{ width: "15%" }}>
                                <div className="form-group text-center">
                                  <label>Action</label>
                                  <br />
                                  <div className="d-flex justify-content-around">
                                    <button
                                      className="btn btn-success"
                                      onClick={(eve) => addNewParentTask(eve)}
                                      disabled={formSubmitted}
                                    >
                                      +
                                    </button>
                                    <button
                                      className="btn btn-danger"
                                      onClick={(eve) =>
                                        removeParentTask(index, eve)
                                      }
                                      disabled={formSubmitted}
                                    >
                                      -
                                    </button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {/* Child Start */}
                            <tr style={{ display: "flex", width: "100%" }}>
                              <th style={{ width: "33.5%" }}>
                                <div className="form-group">
                                  <label> Sub Task {index + 1}</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Sub Task"
                                    id="sub_category"
                                    name="sub_category"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        `task[${index}].sub_category`,
                                        event.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.task[index].sub_category
                                    }
                                    required
                                    disabled={formSubmitted}
                                  />
                                </div>
                              </th>
                              {/* Child End */}

                              {/* Sub Child Task Start */}
                              <th style={{ width: "33.5%" }}>
                                <div className="form-group">
                                  <label>Activity/Task Title {index + 1}</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Activity / Task Title"
                                    id="title"
                                    name="title"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        `task[${index}].title`,
                                        event.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.task[index].title}
                                    required
                                    disabled={formSubmitted}
                                  />
                                </div>
                              </th>

                              <th style={{ width: "33.5%" }}>
                                <div className="form-group">
                                  <label>Name {index + 1}</label>
                                  <select
                                    className="form-control show-tick"
                                    id="assigned_to_user_id"
                                    name="assigned_to_user_id"
                                    onChange={(event) =>
                                      setFormikEmployee(
                                        event,
                                        event.target.value,
                                        index
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.task[index]
                                        .assigned_to_user_id
                                    }
                                    required
                                    disabled={formSubmitted}
                                  >
                                    <option value="" disabled>
                                      Select Employee
                                    </option>
                                    {employee.map((employee) => (
                                      <option
                                        key={employee.employee_user_id}
                                        value={employee.employee_user_id}
                                      >
                                        {employee.employee_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </th>
                            </tr>

                            <tr style={{ display: "flex", width: "100%" }}>
                              <th style={{ width: "25%" }}>
                                <div className="form-group">
                                  <label> Task Time </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Time(Minutes)"
                                    id="estimated_time"
                                    name="estimated_time"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        `task[${index}].estimated_time`,
                                        event.target.value
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.task[index].estimated_time
                                    }
                                    required
                                    disabled={formSubmitted}
                                  />
                                </div>
                              </th>
                              <th style={{ width: "25%" }}>
                                <div className="form-group">
                                  <label> Priority {index + 1}</label>

                                  <select
                                    className="form-control"
                                    id="priority"
                                    name={`task[${index}].priority`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.task[index].priority}
                                    required
                                    disabled={formSubmitted}
                                  >
                                    <option value="" disabled>
                                      Select Priority
                                    </option>
                                    <option value="4">Critical</option>
                                    <option value="3">High</option>
                                    <option value="2">Medium</option>
                                    <option value="1">Low</option>
                                  </select>

                                  {formik.touched.task &&
                                  formik.touched.task[index] &&
                                  formik.errors.task &&
                                  formik.errors.task[index]?.priority ? (
                                    <div className={classes.invalidDataError}>
                                      {formik.errors.task[index]?.priority}
                                    </div>
                                  ) : null}
                                </div>
                              </th>

                              <th style={{ width: "25%" }}>
                                <div className="form-group">
                                  <label>Status</label>
                                  <select
                                    className="form-control"
                                    id={`status`}
                                    name={`task[${index}].status`}
                                    onChange={formik.handleChange}
                                    value={formik.values?.status}
                                  >
                                    <option value="" disabled>
                                      Select Status
                                    </option>
                                    <option value="Pending">Pending</option>
                                    <option value="In Progress">
                                      In Progress
                                    </option>
                                    <option value="Ready For Q/A">
                                      Ready For Q/A
                                    </option>
                                    <option value="Done">Done</option>
                                    <option value="Discarded">Discard</option>
                                  </select>
                                </div>
                              </th>

                              <th style={{ width: "25%" }}>
                                <div className="form-group">
                                  <label> Due Date {index + 1}</label>
                                  <div className="input-group">
                                    <DatePicker
                                      className="form-control"
                                      id={`due_date_${index}`}
                                      name={`task[${index}].due_date`}
                                      onChange={(date) => {
                                        formik.setFieldValue(
                                          `task[${index}].due_date`,
                                          date ? formatDate(date) : null
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      selected={
                                        formik.values.task[index].due_date
                                          ? new Date(
                                              formik.values.task[index].due_date
                                            )
                                          : null
                                      }
                                      required
                                      disabled={formSubmitted}
                                      dateFormat="yyyy-MM-dd"
                                      minDate={new Date()} // Disable previous dates
                                    />
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() =>
                                          document
                                            .getElementById(`due_date_${index}`)
                                            .click()
                                        }
                                      >
                                        <BsCalendar />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            </tr>

                            {/* Sub Child Task end*/}
                          </tbody>
                        </table>
                      ))}
                  </div>

                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <tbody></tbody>
                      </table>
                    </div>
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary mr-1 ml-1 "
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => navigate("/admin/project-list")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
