import React, { useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Swal from "sweetalert2";
import DataTable2 from "../../../components/DataTable2";

const CustomTable = ({ data, onCheckboxChange, headers, userName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [statusFilter, setStatusFilter] = useState("");
  const filteredData = data.filter(
    (item) =>
      item.project_name
        .toLowerCase()
        .includes(projectNameFilter.toLowerCase()) &&
      item.assigned_to_username
        .toLowerCase()
        .includes(employeeNameFilter.toLowerCase()) &&
      (statusFilter === "" || item.status === statusFilter)
  );

  const currentData = filteredData.sort((a, b) => {
    return b.new - a.new;
  });

  const handleCheckboxChange = (taskId) => {
    showConfirmMessage(() => {
      axiosPrivate
        .put(`update-task-status/${taskId}`, {
          status: "Ready For Q/A",
        })
        .then(() => {})
        .catch(() => {
          // Handle error if needed
        });
    });
  };

  function showConfirmMessage(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "By clicking 'Yes', this task will be moved to the Q/A department, and you will not be able to modify it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, move to Q/A!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        Swal.fire(
          "Updated!",
          "The task has been moved to the Q/A department. You can no longer modify it.",
          "success"
        );
        window.location.reload();
      } else {
        Swal.fire("Cancelled", "The task remains unchanged.", "error");
      }
    });
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEntriesChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const resultCountText = `Showing results ${indexOfFirstItem + 1} - ${
    indexOfLastItem > data.length ? data.length : indexOfLastItem
  } of ${data.length}`;

  return (
    <div>
      <div className="d-block d-md-flex" style={{ gap: "5px" }}>
        <div className="d-flex form-control">
          <label style={{ marginLeft: "1rem", paddingTop: "0.4rem" }}>
            Show{" "}
          </label>
          <select
            className=""
            value={itemsPerPage}
            onChange={handleEntriesChange}
            style={{ marginLeft: "10px", border: "none" }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          <label style={{ marginLeft: "10px", paddingTop: "0.4rem" }}>
            Entries{" "}
          </label>
        </div>
        <input
          className="form-control"
          type="text"
          placeholder="Search By Project Name"
          style={{ fontSize: "1rem" }}
          value={projectNameFilter}
          onChange={(e) => setProjectNameFilter(e.target.value)}
        />
        <input
          className="form-control"
          type="text"
          placeholder="Search By Employee"
          style={{ fontSize: "1rem" }}
          value={employeeNameFilter}
          onChange={(e) => setEmployeeNameFilter(e.target.value)}
        />
        <select
          className="form-control"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          style={{ fontSize: "1rem" }}
        >
          <option value="">Select Status</option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Ready For Q/A">Ready For Q/A</option>
          <option value="Done">Done</option>
          <option value="Discard">Discard</option>
        </select>
      </div>
      <div style={{ marginTop: "50px", overflowX: "auto" }}>
        {currentData.length > 0 ? (
          <table className="table table-hover table-striped table-vcenter mb-0 table_custom">
            <thead>
              <tr>
                <th className="text-center">#</th>
                {headers
                  .filter(
                    (header) =>
                      !(userName !== "Admin" && header?.name === "Assignee")
                  )
                  .map((header) => (
                    <th key={header?.id} className="text-center">
                      {header?.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {currentData?.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    textDecoration:
                      item.status === "Ready For Q/A" ? "line-through" : "none",
                    color:
                      item.status === "Ready For Q/A" ? "#5a5278" : "inherit",
                  }}
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={item.status === "Ready For Q/A"}
                      onChange={() => handleCheckboxChange(item.id)}
                      disabled={item.status === "Ready For Q/A"}
                    />
                  </td>
                  <td className="text-center">{item?.project_name}</td>
                  <td className="text-center">{item?.title}</td>
                  <td className="text-center">
                    {item?.category_name} | {item?.sub_category}
                  </td>
                  {userName == "Admin" && (
                    <td className="text-center">
                      {item?.assigned_to_username}
                    </td>
                  )}
                  <td className="text-center">{item?.status}</td>
                  <td className="text-center">{item?.due_date}</td>
                  <td className="text-center">{item?.priority}</td>
                  <td className="text-center">{item?.action}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="alert alert-warning text-center" role="alert">
            No Task Available To Perform !
          </div>
        )}
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <button
          style={{ fontSize: "12px" }}
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map(
          (item, index) => (
            <button
              key={index}
              style={{
                fontSize: "12px",
                margin: "0 ",
                background: currentPage === index + 1 ? "#ccc" : "none",
              }}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          style={{ fontSize: "12px" }}
          onClick={nextPage}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        >
          Next
        </button>
        <p style={{ fontSize: "12px", marginTop: "15px" }}>{resultCountText}</p>
      </div>
    </div>
  );
};

export default CustomTable;
