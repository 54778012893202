import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { useState,useEffect,useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import AuthContext from '../../context/Auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = (props) => {
  const navigate = useNavigate();
  let history = useLocation();

  const {user} = useContext(AuthContext);

  // useEffect(()=>{
  //   if(!user.token) {
  //     navigate('/signin');
  //   }else{
  //       const lastLocation = localStorage.getItem('lastLocation');
  //       if(lastLocation) {
  //         localStorage.removeItem('lastLocation');
  //         navigate(lastLocation);
  //       } else {
  //         navigate("/admin",{replace:true});
  //       }
  //     }
  // },[]); 

  // useEffect(() => {
  //   function handleBeforeUnload() {
  //     localStorage.setItem('lastLocation', history.pathname);
  //   }
  //   function getLastLocation() {
  //     localStorage.setItem('lastLocation', history.pathname);
  //   }
  //   handleBeforeUnload();

  // }, [history]);

  // useEffect(()=>{
  //   const lastLocation = localStorage.getItem('lastLocation');
  //   if (lastLocation) {
  //     localStorage.removeItem('lastLocation');
  //     navigate(lastLocation);
  //   }
  // },[history]);

// if(!user.token)
// {
//   return null
// }
  return (
    <Fragment>
    <div className="font-montserrat">     
      <div id="main_content">
        <div className="">
            <div>
                <SideBar />
            </div>
            <div className="page">
                {/* <Header /> */}
                <Outlet />
                <Footer />
            </div>
        </div>
      </div>
    </div>
    < ToastContainer/>
    </Fragment>
  );
};

export default Main;
