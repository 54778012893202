import axios from 'axios';
import { axiosPublic } from './axiosPublic';

const axiosApiSigninInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: {
		"Content-Type": "multipart/form-data",
	}
});

axiosApiSigninInstance.interceptors.request.use(
	async config => {
		config.headers = {
			'Accept': 'application/json',
		}
		return config;
	},
	error => {
		Promise.reject(error)
	});

export default axiosApiSigninInstance;