import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './ExpensesStyle.module.css';

const validationRules = {
	expense_type_id: Yup.string().required('Role is required'),

	// expense_type_id: Yup.string()
	// 	.max(120, 'Name must not exceed 120 characters')
	// 	.required('Name is required'),
};

const EditExpensesType = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

	const UserId = JSON.parse(localStorage.getItem('userData'));
	const Id = UserId.userId;

	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		axiosPrivate
			.get('expensetypes')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get(`expenses/get-expense/${id}`)
			.then((response) => {
				// setGetData([{ ...response.data.data }]);

				if (response.data.code == 200) {
					for (let i = 0; i < response.data.data.length; i++) {
						response.data.data[i]['files'] = '';
					}
					setTableData([...response.data.data]);
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [roles]);

	const initialValues = [
		{
			userId: Id,
			expense_type_id: '',

			old_expense_id: id,
			expense_date: '',
			expense_amount: '',
			expense_description: '',
			// files: '',
			status: '',
		},
	];

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			console.log('tabledata', tableData);

			const data = tableData.map((item) => {
				return {
					//   ...item,
					old_expense_id: id,
					expense_date: item.expense_date,
					expense_amount: item.expense_amount,
					expense_description: item.expense_description,
					status: item.status,
				};
			});

			var values = {
				userId: id,
				expense_type_id: values.expense_type_id,

				expense: data,
			};

			console.log('values', values);

			axiosPrivate
				.put(`expenses/update/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'expenses type updated successfully' });
					navigate('/admin/expenses');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	const addNewRow = (eve) => {
		eve.preventDefault();
		setTableData([
			...tableData,
			{
				expense_date: '',
				expense_amount: '',
				expense_description: '',
				// files: '',
				status: '',
			},
		]);
	};

	const removeExpensesTask = (index, eve) => {
		eve.preventDefault();
		if (tableData.length > 1) {
			setTableData(tableData.filter((_, i) => i !== index));
		}
		return true;
	};

	const handleChangeRow = (evnt, index) => {
		const { name, value } = evnt.target;

		setTableData(
			tableData.map((row, i) => (i === index ? { ...row, [name]: value } : row))
		);
	};

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										{tableData.map((val, index) => {
											return (
												<>
													<div className="card-header">
														<strong>Edit Expenses {index}</strong>
													</div>

													<div className="col-lg-12 col-md-12 col-sm-12">
														<div className="form-group">
															<select
																className="form-control show-tick"
																id="expense_type_id"
																name="expense_type_id"
																onChange={(evnt) =>
																	handleChangeRow(evnt, index)
																}
																value={val.expense_type_id}
															>
																<option>Select Expenses Type</option>
																{roles.map((role, key) => (
																	<option value={role.id}>
																		{role.expense_type}
																	</option>
																))}
															</select>
														</div>
													</div>

													<table className=" w-100">
														<thead key={index}>
															<tr>
																<th>
																	<input
																		type="hidden"
																		name="old_expense_id"
																		value={val.id != undefined ? val.id : null}
																	/>
																</th>
																<th>
																	<div className="form-group">
																		<label>Date</label>
																		<input
																			className="form-control "
																			type="date"
																			onChange={(evnt) =>
																				handleChangeRow(evnt, index)
																			}
																			value={val.expense_date}
																			// value={
																			// 	val.expense_date
																			// 		? new Date(val.expense_date)
																			// 				.toISOString()
																			// 				.substr(0, 10)
																			// 		: ''
																			// }
																			name="expense_date"
																		/>
																	</div>
																</th>
																<th>
																	<div className="form-group">
																		<label>Amount</label>
																		<input
																			className="form-control "
																			type="number"
																			onChange={(evnt) =>
																				handleChangeRow(evnt, index)
																			}
																			value={val.expense_amount}
																			name="expense_amount"
																		/>
																	</div>
																</th>
																<th>
																	<div className="form-group">
																		<label>Description</label>
																		<input
																			className="form-control"
																			type="text"
																			onChange={(evnt) =>
																				handleChangeRow(evnt, index)
																			}
																			value={val.expense_description}
																			name="expense_description"
																		/>
																	</div>
																</th>
																{/* <th className="w-25">
																	<div className="form-group">
																		<label>File</label>
																		<input
																			className="form-control pt-0 "
																			type="file"
																			onChange={(evnt) =>
																				handleChangeRow(evnt, index)
																			}
 																			name="files"
																		/>
																	</div>
																</th> */}
																<th>
																	<div className="form-group">
																		<label>Status</label>
																		<select
																			className="form-control show-tick"
																			id="status"
																			name="status"
																			onChange={(evnt) =>
																				handleChangeRow(evnt, index)
																			}
																			onBlur={formik.handleBlur}
																			value={val.status}
																		>
																			<option value="Pending">Pending</option>
																			<option value="Approved">Approved</option>
																			<option value="Reject">Reject</option>
																		</select>
																	</div>
																</th>
																<th>
																	<div className="form-group text-center">
																		<label className="m-0">Action</label>
																		<br />
																		<div className="d-flex justify-content-around">
																			<div className="btn d-flex">
																				<div>
																					<button
																						className="border-0 bg-white "
																						onClick={(eve) => addNewRow(eve)}
																					>
																						<GrAddCircle size={20} />
																					</button>
																				</div>
																				<div>
																					<button
																						className="border-0 bg-white"
																						onClick={(eve) =>
																							removeExpensesTask(index, eve)
																						}
																					>
																						<GrSubtractCircle size={20} />
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</th>
															</tr>
														</thead>
													</table>
												</>
											);
										})}

										<div className="col-12">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>

											<button type="submit" className="btn btn-primary">
												UPDATE
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												CLOSE
												onClick={() => navigate('/admin/expenses')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditExpensesType;
