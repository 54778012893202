import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { errorToast, successToast } from "../../../utils/Toaster";
const EditAddTask = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [projectId, setProjectId] = useState("");

  const formik = useFormik({
    initialValues: {
      title: "",
      category_name: "",
      sub_category: "",
      description: "",
      assigned_to_user_id: "",
      estimated_hours: "",
      priority: "",
      due_date: new Date().toISOString().split("T")[0],
    },
    // validationSchema: Yup.object({
    //   category_name: Yup.string().required("category is required"),
    //   sub_category: Yup.string().required("Sub category is required"),
    //   description: Yup.string().required("Description is required"),
    //   assigned_to_username: Yup.string().required("Name is required"),
    //   estimated_hours: Yup.number().required("Estimated Hours is required"),
    //   priority: Yup.string().required("Priority is required"),
    // }),

    onSubmit: async (values) => {
      try {
        const response = await axiosPrivate.put(`task/update/${id}`, values);
        successToast("Task updated successfully");
        navigate(-1);
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    axiosPrivate
      .get(`tasksById/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          formik.setValues(response.data?.data);
          setProjectId(response.data?.data?.project_id);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("users")
      .then((response) => {
        const fetchedEmployeeOptions = response.data.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
          role_name: employee.role_name,
        }));
        setEmployee(fetchedEmployeeOptions);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message);
      });
  }, []);

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix"></div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table
                        className="table"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "50%" }}>
                              <div className="form-group">
                                <label>Category</label>
                                <input
                                  type="text"
                                  name={`category_name`}
                                  id={`category_name`}
                                  className="form-control show-tick"
                                  onChange={formik.handleChange}
                                  value={formik.values?.category_name}
                                  onBlur={formik.handleBlur}
                                ></input>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ display: "flex", width: "100%" }}>
                            <th style={{ width: "50%" }}>
                              <div className="form-group">
                                <label>Sub Category</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Sub Task"
                                  id={`sub_category`}
                                  name={`sub_category`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.sub_category}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </th>
                            {/* Child End */}

                            {/* Sub Child Task Start */}

                            <th style={{ width: "50%" }}>
                              <div className="form-group">
                                <label>Employee Name </label>
                                <select
                                  name={`assigned_to_user_id`}
                                  id={`assigned_to_user_id`}
                                  className="form-control show-tick"
                                  onChange={formik.handleChange}
                                  value={formik.values?.assigned_to_user_id}
                                >
                                  <option value="">-- Please select --</option>
                                  {employee?.map((employee) => (
                                    <option value={employee?.value}>
                                      {employee?.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </th>

                            <th style={{ width: "100%" }}>
                              <div className="form-group">
                                <label>Activity/Task Title</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Activity / Task Title"
                                  id={`title`}
                                  name={`title`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.title}
                                />
                              </div>
                            </th>
                          </tr>
                          <tr style={{ display: "flex", width: "100%" }}>
                            <th style={{ width: "25%" }}>
                              <div className="form-group">
                                <label>Hours </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="hours"
                                  id={`estimated_hours`}
                                  name={`estimated_hours`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.estimated_hours}
                                />
                              </div>
                            </th>
                            <th style={{ width: "25%" }}>
                              <div className="form-group">
                                <label> Priority</label>
                                <select
                                  className="form-control"
                                  id={`priority`}
                                  name={`priority`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.priority}
                                >
                                  <option value="" disabled>
                                    Select Priority
                                  </option>
                                  <option value="4">Critical</option>
                                  <option value="3">High</option>
                                  <option value="2">Medium</option>
                                  <option value="1">Low</option>
                                </select>
                              </div>
                            </th>
                            <th style={{ width: "25%" }}>
                              <div className="form-group">
                                <label>Status</label>
                                <select
                                  className="form-control"
                                  id={`status`}
                                  name={`status`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.status}
                                >
                                  <option value="" disabled>
                                    Select Status
                                  </option>
                                  <option value="Pending">Pending</option>
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Ready For Q/A">
                                    Ready For Q/A
                                  </option>
                                  <option value="Done">Done</option>
                                  <option value="Discarded">Discard</option>
                                </select>
                              </div>
                            </th>
                            <th style={{ width: "25%" }}>
                              <div className="form-group">
                                <label>Due Date</label>
                                <div className="input-group">
                                  <input
                                    type="date"
                                    className="form-control"
                                    id={`due_date`}
                                    name={`due_date`}
                                    min={new Date().toISOString().split("T")[0]} // Set min attribute using the current date
                                    onChange={formik.handleChange}
                                    value={
                                      new Date(formik.values?.due_date)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                  />
                                </div>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ml-2"
                      data-dismiss="modal"
                      onClick={() =>
                        navigate(-1)
                      }
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAddTask;
