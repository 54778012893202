import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectStyle.module.css";
import DataTable2 from "../../../components/DataTable2";
import EditTeam from "../../hrms/Team/EditTeam";
import { Link } from "react-router-dom";
import EditAddTask from "../ProjectTaskList/EditAddTask";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { set } from "react-hook-form";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";
import { FaEye } from "react-icons/fa";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  // title: Yup.string()
  //   .max(120, "Name must not exceed 120 characters")
  //   .required("Name is required"),
  // estimated_hours: Yup.string()
  //   .max(120, "Time must not exceed 30 characters")
  //   .required("Time is required"),
  // type: Yup.string()
  //   .max(120, "Type must not exceed 30 characters")
  //   .required("Type is required"),
};

const EditProject = () => {
  let { id } = useParams();
  const { loading, setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [employeeNames, setEmployeeNames] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isFormModified, setIsFormModified] = useState(false);

  const [editTaskVisible, setEditTaskVisible] = useState(false);
  // const [taskIdToEdit, setTaskIdToEdit] = useState(null);

  const headers = [
    { name: "Task Category", field: "sub_category", sortable: true,classKey: "w60" },
    { name: "Task Name", field: "task_name", sortable: true, classKey: "" },
    { name: "Assignee ", field: "assigned_to_user_name", sortable: true,   classKey: "", },
    { name: "Priority", field: "priority", sortable: true, classKey: "" },
    { name: "Est.Hours", field: "estimated_time", sortable: true, classKey: "" },
    { name: "Actual Hours", field: "actual_time", sortable: true, classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];

  const searchItems = ["project_name"];

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`all-project-details-by-project-id/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          formik.setValues(response?.data?.data[0]);
          setTeamName(response?.data?.data[0]?.teams[0]?.team_name || "");
          setTasks(response?.data?.data[0]?.tasks);
          setEmployeeNames(
            response?.data?.data[0]?.teams[0]?.team_members?.map(
              (member) => member.employee_name
            ) || []
          );
        }
        setIsFormModified(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const showEditTask = (task_id) => {
    setIsLoading(true);
    axiosPrivate
      .get(`tasksById/${task_id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setTaskDetails(response.data.data);
          setEditTaskVisible(true);
        } else {
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (e) => {
    formik.handleChange(e);
    setIsFormModified(true);
  };

  const formik = useFormik({
    initialValues: {
      project_name: "",
      estimated_hours: "",
      type: "",
      // client_user: "",
      // team_name: "",
      // employee_name: "",
      // task_id: "",
      // status: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setFieldError }) => {
      const requestData = {
        project_name: values.project_name,
        estimated_hours: values.estimated_hours,
        type: values.type,
        // team_name: values. team_name,
        // employee_name: values. employee_name,
        // task_id: values.task_id,
        // status: values.status,
        // client_user_: values.client_user,
      };
      setIsLoading(true);
      axiosPrivate
        .put(`project/update/${id}`, requestData)
        .then((response) => {
          successToast("Project updated successfully");
          setProjectId(response.data.data.insertId);
          formik.setFieldValue("project_id", response.data.data.insertId);
          navigate("/admin/project-list");
          setIsLoading(false);
        })
        .catch((error) => {
          errorToast(error?.response?.data?.message);
          setIsLoading(false);
          if (error.response) {
            if (error.response.status == 422) {
              for (let i = 0; i < error.response.data.errors?.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  const taskData = tasks?.map((value) => {
    let buttons = [];
    buttons.push(
      <Link
        key="viewButton##1"
        type="button"
        to={`/admin/project-ticket-list/view/${value?.task_id}`}
        className="btn btn-icon btn-outline-info mr-1"
        title="View"
      >
        <FaEye />
      </Link>
    );
    buttons.push(
      <Link
        key={`editButton##${value?.task_id}`}
        type="button"
        to={`/admin/project-taskboard/edit/${value?.task_id}`}
        onClick={() => showEditTask(value?.task_id)}
        className="btn btn-icon btn-outline-info mr-1"
        title="Edit"
      >
        <i className="fa fa-edit"></i>
      </Link>
    );
    buttons.push(
      <button
        key="deleteButton##1"
        type="button"
        data-id={value.id}
        // onClick={() => projectDeleteHandler(value.id)}
        className="btn btn-icon js-sweetalert btn-outline-danger"
        title="Delete"
      >
        <i className="fa fa-trash-o text-danger"></i>
      </button>
    );

    value["action"] = buttons.length > 0 ? buttons : "-";
    value["assigned_to_user_name"] =
      value?.assigned_to_user?.assigned_to_user_name;
    //  value[]
      value["priority"] = (
        <div>
             {value.priority === "4" && (
            <button
              className="btn"
              style={{
                color: "white",
                background: "#C70039",
                minWidth: "105px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "rgb(255, 89, 89)")}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "#C70039")
              }
            >
              Critical
            </button>
          )}
          {value.priority === "3" && (
            <button
              className="btn"
              style={{
                color: "white",
                background: "#FF6969",
                minWidth: "105px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "redrgb(255, 114, 114")}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "#FF6969")
              }
            >
              High
            </button>
          )}
          {value.priority === "1" && (
            <button
              className="btn"
              style={{
                color: "white",
                background: " #41db41",
                minWidth: "105px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) =>
                (e.target.style.backgroundColor = "green")
              }
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "#41db41")
              }
            >
              Low
            </button>
          )}
          {value.priority === "2" && (
            <button
              className="btn"
              style={{
                color: "white",
                background: "#ffc458",
                minWidth: "105px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) =>
                (e.target.style.backgroundColor = "orange")
              }
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "#ffc458")
              }
            >
              Medium
            </button>
          )}
        </div>
      );
    return value;
  });



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className="tab-pane fade show active"
                  id="user-add"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <strong>Edit Project</strong>
                    </div>
                    <div className="card-body">
                      <div className="row clearfix">
                        <div className="col-lg-3 col-md-16 col-sm-12">
                          {/* <label>
                        Client Name:<span className="required">*</span>
                      </label> */}
                          <div className="form-group">
                            <input
                              id="client_user"
                              name="client_user"
                              type="text"
                              className="form-control"
                              placeholder="Client Name *"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik?.values.client_user}
                              disabled={Boolean(formik?.values?.client_user)}
                            />
                            {formik.touched.client_user &&
                            formik.errors.client_user ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.client_user}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-16 col-sm-12">
                          {/* <label>
                        Project Name:<span className="required">*</span>
                      </label> */}
                          <div className="form-group">
                            <input
                              id="project_name"
                              name="project_name"
                              type="text"
                              className="form-control"
                              placeholder="Project Name *"
                              onChange={(e) => {
                                formik.handleChange(e);
                                setIsFormModified(true);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik?.values.project_name}
                            />
                            {formik.touched.project_name &&
                            formik.errors.project_name ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.project_name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-16 col-sm-12">
                          {/* <label>
                        Time:<span className="required">*</span>
                      </label> */}
                          <div className="form-group">
                            <input
                              id="estimated_hours"
                              name="estimated_hours"
                              type="text"
                              className="form-control"
                              placeholder="Time(hours) *"
                              onChange={handleInputChange}
                              onBlur={formik.handleBlur}
                              value={formik?.values.estimated_hours}
                            />
                            {formik.touched.estimated_hours &&
                            formik.errors.estimated_hours ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.estimated_hours}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-16 col-sm-12">
                          {/* <label>
                        Type:<span className="required">*</span>
                      </label> */}
                          <div className="form-group">
                            <select
                              id="type"
                              name="type"
                              className="form-control"
                              onChange={handleInputChange}
                              onBlur={formik.handleBlur}
                              value={formik?.values.type}
                              required
                            >
                              <option value="" disabled>
                                Select Type
                              </option>
                              <option value="single">Single</option>
                              <option value="recurring">Recurring</option>
                            </select>
                            {formik.touched.type && formik.errors.type ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.type}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-12">
                          {/* <hr className="mt-4" /> */}
                          <div className="table-responsive">
                            <table className="table table-striped"></table>
                          </div>
                          <div>
                            {isFormModified && (
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            )}
                          </div>

                          {/* <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/project-list")}
                      >
                        Close
                      </button> */}
                        </div>
                      </div>

                      <EditTeam
                        project_id={projectId}
                        team_name={teamName}
                        employee_name={employeeNames}
                      />

                      <div className="col-12"></div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="d-flex justify-content-end pt-3 pr-3 mr-3 align-items-center">
                      <Link
                        to={`/admin/project-task/add/${id}`}
                        className="text-decoration-none "
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="fe fe-plus mr-2" />
                          Add New Task
                        </button>
                      </Link>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        {
                          <DataTable2
                            lists={taskData}
                            headers={headers}
                            searchItems={searchItems}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {editTaskVisible && <EditAddTask taskDetails={taskDetails}   />}
    </div>
  );
};

export default EditProject;
